import {
    Account,
    Address,
    AssociatedParty,
    ComboBox,
    OfferAssociatedParty,
    Order,
    OrderAsset,
    OrderFinancing,
    OrderItem,
    OrderNetwork,
    OrderNetworkNode,
    OrderPartyRoleEnum,
    OrderStatusEnum,
    Organization,
    PartyAddress,
    PartyCorrespondent,
    PartyCorrespondentStatus,
    Person,
    Picklist,
    PicklistItem,
    Role,
    Task,
    TaskSearchCriteria,
    Third,
    ThirdType
} from "@/types";
import {
    calculateMarginAPI,
    createAssetDelivery,
    createAssetTradeIn,
    createNetwork,
    createNetworkNode,
    createOrder,
    createOrderAlert,
    createOrderFinancing,
    createOrderParty,
    createOrderPlanning,
    duplicateOrder,
    getNetwork,
    getNetworkNode,
    getOrder,
    getOrderAssetDelivery,
    getOrderAssetTradeIn,
    getOrderItem,
    getOrderOrchestration,
    getOrderParty,
    getParty,
    saveAssignedUser,
} from "@/store/services/order/orderApi";
import {deepClone, deleteAttributes, deleteNonRequiredAttributes, formatDateRecursively, getEntity, getOperator, globalConfig, headers, Masks, mountSelectFields, Paths, removeDuplicatesPreferences, sleep, unmountSelectFieldsV2, UON, upsertWithDataReturn} from "@/utils";
import {buildAddress, getBuildThird, getThird} from "../searchThird";
import {settings} from "@/settings";
import {getTaskHistoryByEntity, getTaskUserAction, initWorkflowProcess} from "@/store/services/task/workflow";
import {getTasks} from "@/store/services/task/taskService";
import store from "@/store";
import {variablesWorkflowOrder} from "@/utils/configuration/table-config";
import {getOrderFinancing, getOrderFinancingInit} from "@/store/services/order/financing/orderFinancingService";
import axios from "axios";
import { api } from "@/auth/api";
import { buildAnalysis, buildBirthplace, buildBusinessDataPerson, buildCitizenshipsPerson, buildJobs, buildPartyContacts, buildSituation, saveContactCorrespondent, savePartyAddresses } from "../thirdService";
import { useApi } from "@/requests/useApi";
import i18n from "@/i18n";
import { formatDate, formatDateLocale } from "@/utils/configuration/formatters-config";
import { buildPreferences } from "@/commons/party-middleware";
import { getAssociatedParty } from "../offerService";
import moment from "moment";
import { getInOperator } from "../picklist";
import { SaveCarProduct, associateCatalogsToProduct, buildInventory, getProducDocument } from "../configuration/AssetService";
import classification from "@/components/order/party/classification/classification.vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import { createOrderPayPlan } from "../objective/objectiveService";
const api_query_url = settings.api_query_url;

export const saveOrder = async (order: Order,orderParties?:any) => {
    const payload = deepClone(order)
    delete payload.config
    let orderPartiesBody: any
    if(orderParties){
    const orderPartiesClone = deepClone(orderParties)
     orderPartiesBody = await handleOrderParties(orderPartiesClone)
    }else{
        if(order.orderParties){
        const orderPartiesClone =deepClone(order.orderParties)
        for(const orderparty of orderPartiesClone){
            orderparty.orderParty={resourceUid:orderparty.orderParty.resourceUid}
        }
         orderPartiesBody = order.orderParties
        }

    }
   
    if (payload.orderCommissions  && payload.orderCommissions.length>0) {
    for (const commission of payload.orderCommissions){
        delete commission.role
       if(commission.adjustAmount)
          commission.adjustAmount={
              amount: typeof commission.adjustAmount === 'string' ? commission.adjustAmount : commission.adjustAmount.amount,
              currency:"EUR"
              }
        }
    }
    formatDateRecursively(payload, 'DD/MM/YYYY', Masks.dateMask)
    await handleOrderItem(payload)

    const networkResponse = await  saveNetwork(order.orderNetworks)
    if (networkResponse) {
        payload.orderNetworks = {
            resourceUid: networkResponse.resourceUid
        }
    }

    const networkNodeResponse = await  saveNetworkNode(order.orderNetworkNodes)
    if (networkNodeResponse) {
        payload.orderNetworkNodes = {
            resourceUid: networkNodeResponse.resourceUid
        }
    }

    let orderFinancingResourceUid = null
    //It means update mode
    if(order.financing && order.financing.resourceUid){
        orderFinancingResourceUid = order.financing.resourceUid
    }
    order.financing = await handleOrderFinancing(orderPartiesBody, order)

    if (orderPartiesBody.length) {
        const listOfOrderParties=[]
        for(const party of orderPartiesBody){
            const orderParty={resourceUid:party.orderParty.resourceUid}
            party.orderParty=orderParty
            listOfOrderParties.push(party)

        }
        payload.orderParties=listOfOrderParties
    }

    //It means that the user does not want to create a new financing
    //TODO - check with Marwa if the financing is mandatory
    if(order.financing){
        if(orderFinancingResourceUid){
            order.financing.resourceUid = orderFinancingResourceUid
        }

        const financingResponse = await saveFinancing(order.financing)
        if (financingResponse) {
            payload.financing = {
                resourceUid: financingResponse.resourceUid
            }
        }
    }
    //Those two deletes will be romoved and a corresponding function for each will be added when they are added in the DB
    delete payload.alertList
    delete payload.orderSubvention

    if (payload.resourceUid === '') {
        delete payload.resourceUid
    }
    const _payload = deepClone(payload)
    _payload.orderCommissions.forEach((item: any) => {
         delete item?.beneficiary?.associatedParty
         delete item?.beneficiary?.businessData
         delete item?.beneficiary?.daaq
    })
    const margeList =(await calculateMarginAPI(_payload )).data
    const orderPayload = saveMargin(payload,margeList);
    const orderCreated =await createOrder(orderPayload)
    return orderCreated
}


const handleOrderItem = async (order: Order) => {
    for (const orderItem of order.orderItems || []) {
        if (orderItem.orderAssets) {
            if(orderItem.effectiveDeliveryDate){
                orderItem.effectiveDeliveryDate =  moment(new Date(orderItem.effectiveDeliveryDate)).format('YYYY-MM-DD HH:mm:ss')
            }
            if(orderItem.initialDeliveryDate){
                orderItem.initialDeliveryDate =  moment(orderItem.initialDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            if(orderItem.previsionDeliveryDate){
                orderItem.previsionDeliveryDate =  moment(orderItem.previsionDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            const orderAsset = orderItem.orderAssets[0]
            delete orderAsset.assetTradeIn
            delete orderAsset.taxValue
            await handleOrderAssets(orderItem,orderAsset)
            if(orderItem.orderAssets.length >1){
                const orderAssetTradeIn = orderItem.orderAssets[1]
                orderAssetTradeIn.flagNew=false
                orderAssetTradeIn.flagVD=false
                if((orderAssetTradeIn.assetTradeIn && orderAssetTradeIn.assetTradeIn.origin)){
                    delete  orderAssetTradeIn.assetDelivery
                    delete orderAssetTradeIn.orderAssetServices
                    if(orderAssetTradeIn.assetProduct&&orderAssetTradeIn.assetProduct.resourceUid){
                        await handleOrderAssets(orderItem,orderAssetTradeIn)
                    }else{
                        const bodyCar= handleBodyForSaveCar(orderAssetTradeIn,orderItem.reference)
                        const car = await SaveCarProduct(bodyCar)
                        if(!(car.inventoryItems&&car.inventoryItems.length>0)){
                            await buildInventory(bodyCar,car)
                        }
                        orderAssetTradeIn.assetProduct={
                            objectType:car.objectType,
                            resourceUid:car.resourceUid,
                            systemUid:car.systemUid
                        }
                        await handleOrderAssets(orderItem,orderAssetTradeIn)
                    }
                }else{
                    orderItem.orderAssets.splice(1, 1);
                }
            }
        }


    }
}



const handleOrderAssetServices =  (orderAssetServices: any) => {
    const ListOrderAssetServices=[]
    for(const orderAssetservice of orderAssetServices){
        delete orderAssetservice.type.type
        orderAssetservice.purchase = orderAssetservice.purchase.includes('true')
        ListOrderAssetServices.push(orderAssetservice)
    }
    return ListOrderAssetServices
}


export const saveOrderParties = async (orderParties: any) => {
    const result = []
    for(const orderParty of orderParties) {
        const payload = orderParty.orderParty
        delete payload.third
        if (payload.resourceUid === '') {
            delete payload.resourceUid
        }
        result.push(await createOrderParty(payload))
    }
    return result
}

export const saveNetwork = async (orderNetwork?: OrderNetwork) => {
    if (orderNetwork) {
        if (orderNetwork.resourceUid === '') {
            delete orderNetwork.resourceUid
        }
        return createNetwork(orderNetwork)
    }
}

export const saveNetworkNode = async (orderNetworkNode?: OrderNetworkNode) => {
    if (orderNetworkNode) {
        if (orderNetworkNode.resourceUid === '') {
            delete orderNetworkNode.resourceUid
        }
        return createNetworkNode(orderNetworkNode)
    }
}

export const saveAssetTradeIn = async (assetTradeIn?: any) => {
        return createAssetTradeIn(assetTradeIn)

}


export const saveAssetDelivery = async (assetDelivery?: any) => {
  
    if (assetDelivery) {
        if (assetDelivery.resourceUid === '') {
            delete assetDelivery.resourceUid
        }
        if(assetDelivery.effectiveDeliveryDate){
            assetDelivery.effectiveDeliveryDate =  moment(assetDelivery.effectiveDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
        }
        if(assetDelivery.initialDeliveryDate){
            assetDelivery.initialDeliveryDate =  moment(assetDelivery.initialDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')

        }
        if(assetDelivery.previsionDeliveryDate){
            assetDelivery.previsionDeliveryDate =  moment(assetDelivery.previsionDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
        }
        return createAssetDelivery(assetDelivery)
    }
}



async function handleParty(orderParty: any) {
  //  const orderPartyResourceEntity = await getOrderParty(orderParty.resourceUid)
  const orderPartyResourceEntity = orderParty
    const type = orderParty.party.objectType === "odm.party.party.person.frenchperson" ? "party-FrenchPerson" : "party-FrenchOrganization"
    orderPartyResourceEntity.third = await getBuildThird(orderParty.party.rawData?.responseData, type, '')
    return orderPartyResourceEntity;
}

export const getOrderEditMode = async (resourceUid: string) => {
    const order = await getOrderOrchestration(resourceUid)
    const {
        orderNetworks,
        orderNetworkNodes,
        orderParties,
        orderCommissions,
        orderSubsidies,
        orderItems
    } =  order
        order.orderNetworks.network.resourceUid = {id:orderNetworks.network.resourceUid,label:orderNetworks.network.resourceUid}
        order.orderNetworkNodes.networkNode.resourceUid = {id:orderNetworkNodes.networkNode.resourceUid,label:orderNetworkNodes.networkNode.resourceUid}

   // order.orderNetworks = await getNetwork(orderNetworks.resourceUid)
  //  order.orderNetworkNodes = await getNetworkNode(orderNetworkNodes.resourceUid)
    const listOfOrderPartie=[]
    for(const party of orderParties){
        const orderPartyResourceEntity = await handleParty(party.orderParty);
        party.orderParty = orderPartyResourceEntity
        listOfOrderPartie.push(party)
    }
    order.orderParties = listOfOrderPartie
    const {orderParty} = order.orderParties?.filter(
        (s: any) => s.orderParty.role?.resourceUid=== OrderPartyRoleEnum.CUSTOMER
      )[0]
   
    order.config = {
        orderParty
    }
    await handleOrderItemEditMode(orderItems)
    await handleOrderCommissionEditMode(orderCommissions)
    await handleOrderSubsidiesEditMode(orderSubsidies)
    await handelDocumentsAsset(orderItems)
    await handelDocumentsParty(order.orderParties)
    if(order.financing && order.financing.resourceUid){
        const financingAssociatedParty =order.orderParties.filter(
            (s: any) => s.orderParty.role?.resourceUid=== OrderPartyRoleEnum.FINANCIER
          )[0]
        
        if(order.financing.contribution&&order.financing.contribution.amount){
            order.financing.contribution=order.financing.contribution.amount
        }
        if(order.financing.financedAmountWoTax&&order.financing.financedAmountWoTax.amount){
            order.financing.financedAmountWoTax=order.financing.financedAmountWoTax.amount
        }
        if(order.financing.financedAmountWTax&&order.financing.financedAmountWTax.amount){
            order.financing.financedAmountWTax=order.financing.financedAmountWTax.amount
        }
        if(order.financing.firstRent&&order.financing.firstRent.amount){
            order.financing.firstRent=order.financing.firstRent.amount
        }
        if(order.financing&&order.financing.type&&order.financing.type.resourceUid ){
            const type = {
                label:order.financing.type.resourceUid,
                value:order.financing.type.resourceUid
            }
            order.financing.type=type
        }
        if (  order.financing && order.financing.financedAmountWTax && !order.financing.financedAmountWoTax){
          let tax=0;
          const newAmountWoTax = order.financing.financedAmountWTax / (1+tax/100)
          order.financing.financedAmountWoTax = newAmountWoTax;
        }

         store.state.orderModule.orderFinancingModule.financing = order.financing
    store.state.orderModule.orderFinancingModule.financing!.associatedParty = financingAssociatedParty?{ "orderParty": financingAssociatedParty.orderParty }:undefined
    }else{
        store.state.orderModule.orderFinancingModule.financing = await getOrderFinancingInit();
    }

    return order
}


export const getPartyName = async (resourceUid: string, objectType: string) => {
    const party = await getParty(resourceUid, objectType)
    if (objectType.includes('person')) {
        return party.firstName
    } else {
        return party.name
    }
}
const handleOrderCommissionEditMode = async (orderCommissions: any) => {
  if(orderCommissions){
    for (const orderCommission of orderCommissions) {
      if(orderCommission.adjustAmount===null){
          orderCommission.adjustAmount={amount: 0, currency: 'EUR'}
          }
        if(orderCommission.calculatedAmountWoTax===null){
          orderCommission.calculatedAmountWoTax={amount: 0, currency: 'EUR'}
        }
      if(orderCommission.calculatedAmountWTax===null){
        orderCommission.calculatedAmountWTax={amount: 0, currency: 'EUR'}
        }
        if(orderCommissions.commissionAmountWTax===null){
          orderCommissions.commissionAmountWTax={amount: 0, currency: 'EUR'}
        }
        if(orderCommissions.commissionAmountWoTax===null){
          orderCommissions.commissionAmountWoTax={amount: 0, currency: 'EUR'}
        }

    }
  }
}

const handleOrderSubsidiesEditMode = async (orderSubsidies: any) => {
  if (orderSubsidies){
      for (const orderSubsidie of orderSubsidies) {
        if(orderSubsidie.adjustAmount===null){
          orderSubsidie.adjustAmount={amount: 0, currency: 'EUR'}
            }
          if(orderSubsidie.calculatedAmountWoTax===null){
            orderSubsidie.calculatedAmountWoTax={amount: 0, currency: 'EUR'}
          }
        if(orderSubsidie.calculatedAmountWTax===null){
          orderSubsidie.calculatedAmountWTax={amount: 0, currency: 'EUR'}
          }
          if(orderSubsidie.commissionAmountWTax===null){
            orderSubsidie.commissionAmountWTax={amount: 0, currency: 'EUR'}
          }
          if(orderSubsidie.commissionAmountWoTax===null){
            orderSubsidie.commissionAmountWoTax={amount: 0, currency: 'EUR'}
          }

      }
  }
}





const handleOrderItemEditMode = async (orderItems: OrderItem[]) => {
    for (const orderItem of orderItems) {
        if(orderItem.effectiveDeliveryDate){
            orderItem.effectiveDeliveryDate =  moment(new Date(orderItem.effectiveDeliveryDate)).format('DD/MM/YYYY HH:mm')
        }
        if(orderItem.initialDeliveryDate){
            orderItem.initialDeliveryDate =  moment(new Date(orderItem.initialDeliveryDate)).format('DD/MM/YYYY HH:mm')
          
        }
        if(orderItem.previsionDeliveryDate){
            orderItem.previsionDeliveryDate =  moment(new Date(orderItem.previsionDeliveryDate)).format('DD/MM/YYYY HH:mm')
        }
        const {orderAssets} = orderItem
        if(orderAssets){
            orderAssets.sort((a, b) => {
                if (!a.assetTradeIn) return -1; // a has description, move to top
                if (!b.assetTradeIn) return 1; // b has description, move to top
                return 0; // both have no descriptions, keep original order
              });
        }

        for (const orderAsset of orderAssets || []) {
            const { supplier, assetDelivery } = orderAsset
            if (supplier) {
           //     const orderPartyResourceEntity = await getOrderParty(supplier.resourceUid)
              /*  if (supplier  &&supplier.party) {
                    supplier.party = orderPartyResourceEntity.party
                    supplier.role = orderPartyResourceEntity.role
                    const supplierPartyName = await getPartyName(orderPartyResourceEntity.party.resourceUid, orderPartyResourceEntity.party.objectType)*/
           
                    let  supplierPartyName=''
                    if(supplier?.party?.rawData.responseData){
                        if (supplier?.party?.rawData.responseData.objectType.includes('person')) {
                            supplierPartyName= supplier?.party?.rawData.responseData.firstName
                        } else {
                            supplierPartyName= supplier?.party?.rawData.responseData.name
                        }
                    }
                    orderAsset.config = {
                        supplierPartyName
                    }
                    const type = "party-FrenchOrganization"
                    const third = await getBuildThird( supplier?.party?.rawData.responseData ,type,'')
                    supplier.third=third
                }
            
            /*if (assetDelivery) {
              //  orderAsset.assetDelivery = await getOrderAssetDelivery(assetDelivery.resourceUid)
                if(orderAsset.assetDelivery?.effectiveDeliveryDate){
                    orderAsset.assetDelivery.effectiveDeliveryDate =  moment(orderAsset.assetDelivery.effectiveDeliveryDate, 'YYYY-MM-DD' ).format('DD/MM/YYYY HH:mm:ss')
                }
                if(orderAsset.assetDelivery?.initialDeliveryDate){
                    orderAsset.assetDelivery.initialDeliveryDate =  moment(orderAsset.assetDelivery.initialDeliveryDate,  'YYYY-MM-DD').format('DD/MM/YYYY HH:mm:ss')
        
                }
                if(orderAsset.assetDelivery?.previsionDeliveryDate){
                    orderAsset.assetDelivery.previsionDeliveryDate =  moment(orderAsset.assetDelivery.previsionDeliveryDate,  'YYYY-MM-DD').format('DD/MM/YYYY HH:mm:ss')
                }
                
            }*/
           /* if(orderAsset.assetTradeIn && orderAsset.assetTradeIn.resourceUid){
                orderAsset.assetTradeIn = await getOrderAssetTradeIn(orderAsset.assetTradeIn.resourceUid)
            }*/
            if(orderAsset.amountWoTax===null){
              orderAsset.amountWoTax={amount: 0, currency: 'EUR'}
            }
            if(orderAsset.amountWTax===null){
              orderAsset.amountWTax ={amount: 0, currency: 'EUR'}
            }
            if(orderAsset.bonus===null){
              orderAsset.bonus ={amount: 0, currency: 'EUR'}
            }
            if(orderAsset.bbProvision===null){
              orderAsset.bbProvision ={amount: 0, currency: 'EUR'}
            }
            if(orderAsset.tradeInCommitment === null){

              orderAsset.tradeInCommitment ={amount: 0, currency: 'EUR'}
            }

            if(orderAsset.orderAssetServices){
                for (const service of orderAsset.orderAssetServices){
                    if(service.type&&service.type.resourceUid){
                        service.type.type={value:service.type.resourceUid,label:service.type.resourceUid}
                    }else{
                      service.type={type:{value:'',label:''}}
                    }
                    if(!(service.amountWoTax&&service.amountWoTax.amount)){
                      service.amountWoTax={amount: 0, currency: 'EUR'}
                    }
                    if(!(service.amountWTax&&service.amountWTax.amount)){
                      service.amountWTax={amount: 0, currency: 'EUR'}
                    }
                    if(!(service.commissionAmount&&service.commissionAmount.amount)){
                      service.commissionAmount={amount: 0, currency: 'EUR'}
                    }

                    

                    service.purchase=service.purchase?"true":"false"
                    
                }
            }
            if(orderAsset?.assetTradeIn && orderAsset.assetTradeIn.engagementAmount === null){
              orderAsset.assetTradeIn.engagementAmount = store.getters["orderModule/getAssetTradeInInit"].engagementAmount
            }


        }
        if(orderItem.orderAssets&&orderItem.orderAssets.length===1){
            const orderAsset = store.getters["orderModule/getOrderAssetInit"]
            orderItem.orderAssets.push(orderAsset)
        }
    }
}


export const initOrderWorkflow = async (order: Order) => {
    const {
        data
    } = await getTasks({
        searchVariables: JSON.stringify([
            { name: 'orderReference',  operator: "eq",  value: order.reference }
        ])
    })
    if(!data || !data.length) {
        const variables = extractOrderVariables(order)
        await initWorkflowProcess({ processDefinitionId: settings.processDefinitionId, variables: JSON.stringify(variables) })
    }
}

const extractOrderVariables = (order: any) => {

    const {
        resourceUid,
        reference,
        status,
        channel,
        orderNetworks,
        effectiveDeliveryDate,
        orderNetworkNodes ,
        orderParties
    } = order

    const variables = [
        { variableName: 'module', value: 'Order' },
        { variableName: 'daaq' , value: order.daaq || '' },
        { variableName: 'applicationName', value: 'TWDClientApp' },
        { variableName: 'env', value: settings.env },
        { variableName: 'orderId', value: resourceUid },
        { variableName: 'orderReference', value: reference },
        { variableName: 'orderCreationDate', value: effectiveDeliveryDate },
        { variableName: 'channel', value: channel },
        { variableName: 'orderStatus', value: status?.resourceUid },
        { variableName: 'order', value: JSON.stringify(order) },
    ]

    const orderNetworksId = orderNetworks?.network?.resourceUid?.id
    if (orderNetworksId)
        variables.push({ variableName: 'associated_networks_id', value: orderNetworksId })
    const associatedNetworkNodesId = orderNetworkNodes?.networkNode?.resourceUid?.id
    if (associatedNetworkNodesId)
        variables.push({ variableName: 'associated_network_nodes_id', value: associatedNetworkNodesId })

    const customer: any = (orderParties || [])
        .filter((party: any) => party.orderParty?.role?.resourceUid === 'CUSTOMER')[0]
    if (customer) {
        const third = customer.orderParty.third
        if (third.id.objectType.includes('person')) {
            const person = customer.orderParty.third.person
            const customerName = `${person.firstName} ${person.familyName}`
            variables.push({ variableName: 'customerRessourceUid', value: person.resourceUid })
            variables.push({ variableName: 'customerName', value: customerName })
            variables.push({ variableName: 'customerReference', value: person.reference })
            variables.push({ variableName: 'customerType', value: 'person' })
            variables.push({ variableName: 'customerBirthDate', value: person.birthDate })
        } else {
            const organization = customer.orderParty.third.organization
            variables.push({ variableName: 'customerResourceUid', value: organization.resourceUid })
            variables.push({ variableName: 'customerName', value: organization.name })
            variables.push({ variableName: 'customerReference', value: organization.reference })
            variables.push({ variableName: 'customerType', value: 'organization' })
        }
    }

    return variables

}

export async function getOrderTaskHistory() {
    const taskSearchCriteria: TaskSearchCriteria = {
      entityId: store.state.orderModule.order?.resourceUid || "" ,//"65f04e58aad7522c7dce6d2b",
      initializeVariables: variablesWorkflowOrder };
    const tasklist = await getTaskHistoryByEntity(taskSearchCriteria);
    const result = await setTaskUserAction(tasklist.data);
    return result;
  }
  export async function setTaskUserAction(payload: any) {
    payload.forEach(async (element: Task) => {
      if (element.taskId) {
        element.histories = await (await getTaskUserAction(element.taskId)).data;
        element.opened = true;
      }
    });
  
    return payload.sort((a: any, b: any) => Date.parse(b.creationDate) - Date.parse(a.creationDate));
  }


export const saveFinancing = async (orderFinancing?: OrderFinancing) => {
    if (orderFinancing) {
        if (orderFinancing.resourceUid === '') {
            delete orderFinancing.resourceUid
        }
        return createOrderFinancing(orderFinancing)
    }
}

async function handleOrderFinancing(saveOrderPartiesResponse: any, order: any):Promise<any> {
    let orderFinancingRequest = null
    if(store.state.orderModule.orderFinancingModule.financing?.label){
        const financingParty = saveOrderPartiesResponse.filter((party: any) => party.orderParty?.party?.resourceUid == store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid);
        orderFinancingRequest = {...store.state.orderModule.orderFinancingModule.financing };

        if (financingParty && financingParty[0].orderParty && store.state.orderModule.orderFinancingModule.financing) {
            orderFinancingRequest.financingOrganization = {
                resourceUid: financingParty[0].orderParty.resourceUid,
                objectType: financingParty[0].orderParty.objectType,
                systemUid: financingParty[0].orderParty.systemUid,
            }
        }
        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.contribution >=0) {
            orderFinancingRequest.contribution = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.contribution,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.financedAmountWoTax >=0) {
            orderFinancingRequest.financedAmountWoTax = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.financedAmountWoTax,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.financedAmountWTax >=0) {
            orderFinancingRequest.financedAmountWTax = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.financedAmountWTax,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.firstRent >= 0) {
            orderFinancingRequest.firstRent = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.firstRent,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && orderFinancingRequest.type) {
            orderFinancingRequest.type = orderFinancingRequest.type.value
            //TODO - check with Marwa what we will do about this field - currently is the scale
            orderFinancingRequest.financingProductPack = null
            orderFinancingRequest.objectType = 'odm.order.orderfinancing'
        }
        delete orderFinancingRequest?.scale
        delete orderFinancingRequest?.associatedParty
        delete orderFinancingRequest?.config
    }


    return orderFinancingRequest
}

export function getOrderStatusProgress(filter: any): Promise<ComboBox> {

    return new Promise<any>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: 'order-status-picklist',
        }
      }
     
      const operators = []
      if (filter.resourceUid) {
        operators.push(getOperator("order_order_status", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.resourceUid), "id"))
      }
  
  
      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators
              }
            }
          ]
        }
        qc_header.qc.otherOrders = null
        qc_header.qc.offset = 0
        qc_header.qc.limit = 50
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc)
      })
      axios.get(url, {
        headers: _headers
      }).then(res => {
        let response;
        if (res.data.length>0){
         response = {
                value: res.data[0].id,
                label: res.data[0].id,
                internalCode: res.data[0].internal_code
  
        }
    }
    else {
        response = {}
    }
  
        resolve(response)
  
      }).catch((error) => {
        reject(error);
      });
    })
  }

export function searchAssetOrder(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'asset-order-search-filter',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }

        const operators = []
        if (filter && filter.brand != null) {
            operators.push(getOperator("lower(ppc_brand", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.brand.label + '%').toLowerCase(), "ppc_brand"))
        }
        if (filter && filter.model != null) {
            operators.push(getOperator("lower(ppc_model", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.model.label + '%').toLowerCase(), "ppc_model"))
        }
        if (filter && filter.color != null) {
            operators.push(getOperator("lower(ppc_color", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.color + '%').toLowerCase(), "ppc_color"))  }
        if (filter && filter.activity != null) {
            operators.push(getOperator("product_inventory_item", "type_code", "LIKE", globalConfig.formatters.getLikeValue(filter.activity), "type_code"))
        }
        operators.push(getOperator("product_inventory_item", "status_code", "LIKE", globalConfig.formatters.getLikeValue('IN_STOCK'), "status_code"))
        operators.push(getOperator("product_product_feature","_discriminator","EQUAL",globalConfig.formatters.getEqualValue('product-ProductPack'),"_discriminator"));

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            res.data.map((resItem: any) => {
                return {
                ...resItem,
                    opendetailInfo : false
                }
              })
            resolve(res.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
async function handleOrderParties(orderParties: any):Promise<any> {
    console.log("handleOrderParties")
    const daaqResourceUid=store.state.authModule?.daaqResourceUid
    const listofParties:any[]=[]
    for(const party of orderParties){
        let isOrganization: boolean
    if (party.associatedParty?.third?.type) {
        isOrganization = checkOrganization(party.associatedParty?.third?.type)
    } else {
        isOrganization = checkOrganization(party.associatedParty?.third?.objectType)
    }
    let partie
    if (isOrganization) {
        partie= await buildOrganization(party.associatedParty, orderParties, daaqResourceUid)
    } else {
        partie= await  makePerson(<Person>party.associatedParty?.third?.person, daaqResourceUid);
    }

    const orderParty:any ={
        party: {
            resourceUid:partie.resourceUid,
            objectType: partie.objectType,
            systemUid: partie.systemUid,

        },
        role: {
            resourceUid: party.role_code,
            objectType: 'odm.order.orderpartyrole'
        },
        objectType: 'odm.order.orderparty',
        systemUid: 'odm-order',
    }
    if(party.orderPartyResourceUid){
        orderParty.resourceUid=party.orderPartyResourceUid
    }
    const orderPartyResponse = await createOrderParty(orderParty)
    const orderPartieBody :any ={
        objectType: "odm.order.orderassociatedparty",
        systemUid: "odm-order"

        
    }
    orderPartieBody.orderParty=orderPartyResponse
    if(party.resourceUid){
        orderPartieBody.resourceUid=party.resourceUid
    }
    listofParties.push(orderPartieBody)
    }
    return listofParties
}
function checkOrganization(type: PicklistItem | string | undefined): boolean {
    if (typeof type === 'object') {
        return type.id === ThirdType.ORGANIZATION
    }
    else {
        return !!type?.includes(ThirdType.TYPE_ORGANIZATION)
    }
}
const buildPartyCorrespondent = async (correspondents?: PartyCorrespondent[]) => {
    for (const correspondent of correspondents || []) {
        correspondent.objectType = 'odm.party.partycorrespondent'
        correspondent.status ={
            resourceUid : PartyCorrespondentStatus.ACTIVE
        }
        correspondent.qualificationType.resourceUid = correspondent.qualificationType.type?.value || correspondent.qualificationType.resourceUid
        if (correspondent.qualificationType.type) deleteAttributes(correspondent.qualificationType, ['type'])
        const promises = correspondent.contactCorrespondent.map(item => saveContactCorrespondent(item))
        await Promise.all(promises)
    }
}
function buildIds(person: any) {

    if (person.ids && person.ids.length > 0) {
        person.ids?.forEach((item: any) => {
            item.emissionDate = item.validity.from ? formatDateLocale(item.validity.from, 'DD/MM/YYYY', 'fr') : '';
            item.validity.from = item.validity.from ? formatDateLocale(item.validity.from, 'DD/MM/YYYY', 'fr') : '';
            item.validity.until = item.validity.until ? formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr') : '';
            Object.keys(item.documentType).forEach(index => {
                item.documentType.resourceUid = item.documentType.type?.value;
            });
            delete item.documentType.type
            delete item.isDisabled
        });
    } else {
        delete person.ids
        person.ids = []
    }


}
async function saveAddresses(address: Address, roles?: Role[]) {
    delete address.isDisabled;

    const clonedAddress = deepClone(address);

    delete address.type;

    if (address.postalCode && !address.postalCode.resourceUid) {
        address.postalCode.resourceUid = typeof address.postalCode.type?.value === 'string' ? address.postalCode.type?.value : address.postalCode.type?.value?.value
    }
    if (address.postalCode && address.postalCode.type && address.postalCode.type.value) {
        address.postalCode.resourceUid = typeof address.postalCode.type?.value === 'string' ? address.postalCode.type?.value : address.postalCode.type?.value?.value
    }
    deleteNonRequiredAttributes(address.postalCode, ["resourceUid"]);

    if (address.street) {
        const result = await useApi('odm-party', 'address').upsert(address);
        if (result.response) {
            if (roles && roles.length > 0) {
                for (const role of roles) {
                    const params = {
                        preferences: role.preferences,
                        partyAddress: clonedAddress,
                        addressUID: result.response.resourceUid
                    }
                    const rolePreference = await buildPreferences(params);
                    role.preferences = rolePreference;
                }
            }
            return result.response.resourceUid;
        }
        else console.error(`ERROR: address was not saved [${result.error}]`);
    }
}
const makePersonAddress = async (person: Person) => {
    const promises = person?.addresses?.map(partyAddress => saveAddresses(partyAddress.address, person.roles));
    let livingSituation = ''
    if (promises) {
        const allAddressSaved = await Promise.all(promises)
        const personAddress = []
        let index = 0;
        for (const addr of allAddressSaved) {
            if (addr) {
                const partyAddressRelationship = person?.addresses?.filter(partyAddress => partyAddress.address.resourceUid === addr)[0];
                if (person?.addresses) {
                    livingSituation = (person.addresses[index] as any).livingSituation && (person.addresses[index] as any).livingSituation.resourceUid ? (person.addresses[index] as any).livingSituation.resourceUid : (person.addresses[index] as any).livingSituation  &&  (person.addresses[index] as any).livingSituation.type ? (person.addresses[index] as any).livingSituation.type.value : ''

                }
                const partyAddress: PartyAddress = {
                    resourceUid: partyAddressRelationship?.resourceUid || undefined,
                    objectType: 'odm.party.partyaddress',
                    daaq: "/",
                    address: {
                        resourceUid: addr,
                        objectType: 'odm.party.address'
                    },
                    livingSituation: livingSituation !== '' && livingSituation !== undefined ? {
                        resourceUid: livingSituation
                    } : null,

                }
                personAddress.push(partyAddress)
            }
            index++
        }
        person.addresses = personAddress
    }
}
function validateMaritalStatuses(person: Person) {
    return person?.currentMaritalStatus && person.currentMatrimonialRegime
}
function buildFinancialExposures(financialExposures: any) {
    const exposures: any[] = []
    if (financialExposures && financialExposures.length > 0) {
        financialExposures.map((item: any) => {
            const exposure =
            {
                resourceUid: item.resourceUid,
                objectType: item.objectType,
                systemUid: item.systemUid,
                daaq: item.daaq,
                grossOutstanding: {
                    amount: item.grossOutstanding,
                    currency: "EUR"
                },
                netOutstanding: {
                    amount: item.netOutstanding,
                    currency: "EUR"
                },
                residualValue: {
                    amount: item.residualValue,
                    currency: "EUR"
                },
                flagFinancialPool: item.flagFinancialPool,
                flagRiskPool: item.flagRiskPool,
                shareFinancialPool: item.shareFinancialPool,
                shareRiskPool: item.shareRiskPool,
                effectiveDate: item.effectiveDate?formatDateLocale(item.effectiveDate, 'DD/MM/YYYY', 'fr'):'',
                contractReference: item.contractReference,
                contractValidity: {
                    from: item.contractValidity?.from ? formatDateLocale(item.contractValidity.from , 'DD/MM/YYYY', 'fr'): '',
                    until: item.contractValidity?.until ? formatDateLocale(item.contractValidity.until, 'DD/MM/YYYY', 'fr') : '',
                },
                outstandingType: item.outstandingType?.value ? item.outstandingType?.value : item.outstandingType,
                financialInstitution: item.financialInstitution?.value ? item.financialInstitution?.value : item.financialInstitution,

            }
            exposures.push(exposure)
        })

        return exposures;
    }
    else {
        return []
    }

}
const makePerson = async (person: Person, daaqResourceUid?: string) => {
    unmountSelectFieldsV2(person, [
        'title',
        'nationality',
        'role',
        'status',
        'currentMaritalStatus',
        'currentMatrimonialRegime',
        'nature',
        'periodicity',
        'classifications',
        'gender'
    ]);

    deleteAttributes(person, ['validity', 'relationType', 'managerType', 'show','type','status']);

    person.daaq = daaqResourceUid ?? '/';

    if (person.correspondents) {
        person.correspondents = person.correspondents.filter((correspondent:any) => {
            return correspondent.firstName !== "" && correspondent.familyName !== "" && correspondent.qualificationType.resourceUid !== "";
        });
        await buildPartyCorrespondent(person.correspondents);
    }
    if (person.contacts) {
        for(const contact of person.contacts){
            if(contact.contactMechanism.validity){
                contact.contactMechanism.validity.from=contact.contactMechanism.validity.from?formatDateLocale(contact.contactMechanism.validity.from, 'DD/MM/YYYY', 'fr'):''
                contact.contactMechanism.validity.until=contact.contactMechanism.validity.until?formatDateLocale(contact.contactMechanism.validity.until, 'DD/MM/YYYY', 'fr'):''
            }
            
        }
        await buildPartyContacts(person.contacts);
        person.contacts.forEach((contact:any) =>{
            if( contact.contactMechanism &&  ! contact.contactMechanism.resourceUid){
              deleteAttributes(contact, ['contactMechanism','daaq']);
            }
          } )
    }

    if (person.title && !person.title.resourceUid){
        person.title.resourceUid = 'M'
    }

    if (person.ids && person.ids[0]?.reference!=='') {
        buildIds(person)
    }
    else{
        delete person.ids
        person.ids = []
    }

    if (person.addresses) {
        await makePersonAddress(person);
    }

    if (person.homemakers !== "") {
        buildBusinessDataPerson(person)
    }

    if (person.nationality && person?.nationality.resourceUid !== '') {
        buildCitizenshipsPerson(person)
    } else {
        delete person.nationality
    }

    if ((person.birthplace && person.birthplace.resourceUid && person.birthplace.resourceUid !== '') || (person.birthplace && person.birthplace.postalCode && person.birthplace.postalCode !== '')) {
        buildBirthplace(person)
    } else {
        delete person.birthplace
    }
    if (person.gender && !person.gender.resourceUid) {
        delete person.gender
    }

    if (validateMaritalStatuses(person)) {
        buildSituation(person)
    } else {
        delete person.maritalStatuses
        person.maritalStatuses = []
    }
    
    if (person.revenues && person.revenues.length > 0 && person.revenues[0].analysisDate) {
        for (const revenue of person.revenues){
            if(revenue.detail){
                for(const detail of revenue.detail){
                
                    if(detail.validity&&detail.validity.from){
                        detail.validity.from=formatDateLocale(detail.validity.from, 'DD/MM/YYYY', 'fr')
                    }
                    if(detail.validity&&detail.validity.until){
                        detail.validity.until=formatDateLocale(detail.validity.until, 'DD/MM/YYYY', 'fr')
                    }
                }
            }
            
        }
        buildAnalysis(person)
    } else {
        delete person.revenues
        person.revenues = []
    }
    if (person.financialExposures && person.financialExposures.length > 0) {
        person.financialExposures = buildFinancialExposures(person.financialExposures)
    }
    if (person.jobs && person.jobs.length > 0 && person.jobs[0].employerName) {
        person = await buildJobs(person, true)
        person.jobs?.map((job: any) => {
            if (job.jobLocation && !job.jobLocation.resourceUid) {
                delete job.jobLocation
            }
        })

    } else {
        delete person.jobs
        person.jobs = []
    } if (person.classifications && person.classifications.length > 0) {
        unmountSelectFieldsV2(person.classifications, [
            'partyClass'
        ]);
        person.classifications.forEach((value: any) => {
            delete value.classType
            delete value.isDisabled
            value.classificationDate=formatDateLocale(value.classificationDate, 'DD/MM/YYYY', 'fr')
            value.validity.from=value.validity.from?formatDateLocale(value.validity.from, 'DD/MM/YYYY', 'fr'):''
            value.validity.until=value.validity.until?formatDateLocale(value.validity.until, 'DD/MM/YYYY', 'fr'):''
        })
        person.classifications=person.classifications.filter((s: any) => s.partyClass.resourceUid)
    }
    if (person.accounts) {
        const accounts: Account[] = []
        let i = 0
        for (const item of person.accounts) {
            const data: Account = {
                objectType: 'odm.party.partybankaccount',
                bankAccount: {
                    resourceUid: item.bankAccount.resourceUid,
                    objectType: 'odm.party.bankaccount'
                },
                daaq: '/',
                resourceUid: person.accounts[i].resourceUid ? person.accounts[i].resourceUid : ''
            }
            accounts.push(data);
            i++
        }
        person.accounts = accounts
    }

    deleteAttributes(person, [
        'currentMaritalStatus',
        'currentMatrimonialRegime',
        'homemakers',
        'retirementPassage',
        'birthChildren',
        'removedSituation',
        'age',
    ])
    person.objectType = UON.ObjectType.frenchPerson;
    person.systemUid = UON.SystemUid.odmParty;
    delete person.relations

    if (!person.reference || person.reference === '') {
        person.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
    }
    delete person.driverLicenceDate
    if(person.birthDate){

        person.birthDate=formatDateLocale(person.birthDate, 'DD/MM/YYYY', 'fr')

    }

    return savePerson(person, 'odm-party', daaqResourceUid);
}
const savePerson = async (person: Person, systemUid: string, daaqResourceUid?: string) => {
    person.financialExposures?.forEach((financialExposure: any) => {
        if (financialExposure.outstandingType && financialExposure.outstandingType.value === "") {
            delete financialExposure.outstandingType
        }
        if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
            delete financialExposure.financialInstitution
        }

    })

    if (person.roles) {
        for (const item of person.roles) {
            delete item.isDisabled
            if(item.validity&&item.validity.from){
                item.validity.from=formatDateLocale(item.validity.from, 'DD/MM/YYYY', 'fr')
            }
            if(item.validity&&item.validity.until){
                item.validity.from=formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr')
            }
            if (item.preferences && item.preferences.length > 0) {
                item.preferences = removeDuplicatesPreferences(item.preferences);
            }
        }
    }

    return upsertWithDataReturn(systemUid, person, Paths.frenchPerson, undefined, daaqResourceUid);
}

async function buildOrganization(associatedParty: AssociatedParty | undefined, parties?: OfferAssociatedParty[], daaqResourceUid?: string) {
    if (associatedParty?.third?.organization) {
        const organization = associatedParty.third.organization;
        daaqResourceUid = store?.state?.authModule?.daaqResourceUid
        delete organization.creationDate
        unmountSelectFieldsV2(organization, ['legalCategory', 'activitySector', 'partyClass', 'codeNACE'])
        if (!organization.codeNACE?.resourceUid) {
            delete organization.codeNACE
        }
        organization.daaq = '/',
            organization.objectType = UON.ObjectType.frenchOrganization;
        organization.systemUid = UON.SystemUid.odmParty;
        if (organization.classifications && organization.classifications.length > 0) {
            unmountSelectFieldsV2(organization.classifications, [
                'partyClass'
            ]);
            organization.classifications.forEach((value: any) => {
                delete value.classType
                delete value.isDisabled
                value.classificationDate=formatDateLocale(value.classificationDate, 'DD/MM/YYYY', 'fr')
                value.validity.from=value.validity.from?formatDateLocale(value.validity.from, 'DD/MM/YYYY', 'fr'):''
                value.validity.until=value.validity.until?formatDateLocale(value.validity.until, 'DD/MM/YYYY', 'fr'):''
            })
            organization.classifications=organization.classifications.filter((s: any) => s.partyClass.resourceUid)
        }
        if (organization.financialExposures && organization.financialExposures.length > 0) {
            organization.financialExposures = buildFinancialExposures(organization.financialExposures)
        }
        await buildPartyCorrespondent(organization.correspondents);
        if (organization.correspondents && organization.correspondents.length > 0) {
            organization.correspondents=organization.correspondents.filter((s: any) => s.firstName!=='')
        }
        organization.facilities =[]
        await buildOrganizationAddress(associatedParty.third);
        if (!organization.legalCategory?.resourceUid) {
            delete organization.legalCategory
        }
        

        const clone = deepClone(organization);
        delete clone.manager
        delete clone.relations
        delete clone.codeApe
        delete clone.type
        delete clone.status
        clone.facilities.forEach((value: any) => {
            delete value.livingSituation
        })
        const savedOrganization = saveOrganization(clone, 'odm-party', daaqResourceUid);

        organization.resourceUid = (await savedOrganization).resourceUid

        return savedOrganization;
    }
}

async function buildOrganizationAddress(third: Third) {
    const organization = third.organization;
    if (organization && !!organization.addresses) {
        for (const address of organization.addresses){
            if(address.address && address.address.type&& address.address.type.type&&address.address.type.type.value&&address.address.type.type.value.length>0){
                address.address.type.resourceUid=address.address.type?.type.value[0]
            }
            if(address.address && address.address.type&& address.address.type.type&&address.address.type.type.id&&address.address.type.type.id.length>0){
                address.address.type.resourceUid=address.address.type?.type.id
            }
            if(address.address && address.address.type){
                delete address.address.type.type
            }
        }
        const promises =
            organization.addresses
                .filter((i:any) => !!i.address.postalCode?.postalCode)
                .map((item:any) => savePartyAddresses(item, organization.roles))
        const allPartyAddressSaved = await Promise.all(promises)
        if (organization.addresses) {
            organization.addresses.splice(0, organization.addresses.length)
        }
        organization.addresses?.push(...allPartyAddressSaved);
    }
}
const saveOrganization = async (organization: Organization, systemUid: string, daaqResourceUid?: string) => {
    organization.correspondents?.forEach(correspondent => {

        correspondent.contactCorrespondent.forEach(contact =>{
          if( ! contact.contactMechanism.resourceUid){
            deleteAttributes(contact, ['contactMechanism','daaq']);
          }
        } )

        deleteAttributes(correspondent, ['businessData']);
        deleteNonRequiredAttributes(correspondent.qualificationType, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.contacts?.forEach(contact => {
        deleteNonRequiredAttributes(contact.contactMechanism, ['objectType', 'systemUid', 'resourceUid']);
    })

    if (organization.facilities) {

        organization.facilities.forEach(item => {
            deleteNonRequiredAttributes(item.address, ['objectType', 'systemUid', 'resourceUid']);
            if (item.address?.resourceUid === undefined) {
                item.address = null;
            }
        })
        organization.facilities.forEach(item => {
            delete item.facilityType?.type
        })
    }

    organization.addresses?.forEach(address => {
        deleteNonRequiredAttributes(address.address, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.accounts?.forEach(account => {
        deleteNonRequiredAttributes(account.bankAccount, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.roles?.forEach(role => {
        delete role.isDisabled
        if(role.validity){
            role.validity.from =role.validity.from ? formatDateLocale(role.validity.from, 'DD/MM/YYYY', 'fr') :''
            role.validity.until =role.validity.until ? formatDateLocale(role.validity.until, 'DD/MM/YYYY', 'fr') :''
        }
        unmountSelectFieldsV2(role, ['status', 'role'])
    })

    if (!organization.reference || organization.reference === '') {
        organization.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
    }
    
    organization.financialExposures=organization.financialExposures?.filter((s: any) => 
            s.effectiveDate !== '')

    organization.financialExposures?.forEach((financialExposure: any) => {
        if (financialExposure.outstandingType.value === "") {
            delete financialExposure.outstandingType
        }
        if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
            delete financialExposure.financialInstitution
        }
    })

    buildBalanceSheets(organization);

    if (organization.roles) {
        for (const item of organization.roles) {
            if (item.preferences && item.preferences.length > 0) {
                item.preferences = removeDuplicatesPreferences(item.preferences);
            }
        }
    }

    return upsertWithDataReturn(systemUid, organization, Paths.frenchOrganization, undefined, daaqResourceUid);

}

const buildBalanceSheets = (organization: Organization) => {
    organization.balanceSheets?.forEach(balance => {
        deleteNonRequiredAttributes(balance, ['objectType', 'systemUid', 'resourceUid', 'period', 'country','label', 'supportingDocument','detail', 'status']);
        const countryUID = balance.country.id ? balance.country.id : ''
        const statusUID = balance.status.id ? balance.status.id : ''
        if (countryUID && countryUID !== '') {
            balance.country = {
                resourceUid: countryUID
            }
        }
        if (statusUID && statusUID !== '') {
            balance.status = {
                resourceUid: statusUID
            }
        }
        if (!balance.period) {
            balance.period = { until: '', from: '' }
        }
        if (!(balance.status && balance.status.resourceUid)) {
            delete balance.status
        }
        if (!(balance.country && balance.country.resourceUid)) {
            delete balance.country
        }
        if (balance.detail) {
            balance.detail.forEach((dt: any) => {
                const nature = dt.nature.id;
                dt.nature = {
                    objectType: 'odm.party.balanceitemtype',
                    resourceUid: nature
                };
                dt.objectType = 'odm.party.balanceitem';
                dt.daaq = '/'
            });
        }
        delete balance.isDisabled
    })
}
export const getOrderParties = async (orderParties:any) => {
const list=[]
let i=0
    for(const orderPartie of orderParties){
        i++
      /*  if(orderPartie.orderParty&& orderPartie.orderParty.third){
            const thirdResourceUid =orderPartie.orderParty.third.type.id.toUpperCase().includes('PERSON')? orderPartie.orderParty.third.person.resourceUid:orderPartie.orderParty.third.organization.resourceUid
            const third = await getThird(thirdResourceUid,orderPartie.orderParty.third.type.id,'')
            await buildAddressWithPostalCode(third.addresses)
            
            mountSelectFields(third, ['legalCategory', 'activitySector'])
        if (third.organization && third.organization.facilities) {
          for (const facility of third.organization.facilities) {
            if (!facility.address) {
              facility.address = { ...store.getters["thirdModule/getAddressInit"] }
            }
          }
        }
        if (third.person && third.person.birthplace && third.person.birthplace.type) {
          if (!third.person.birthplace.type.config) {
            third.person.birthplace.type.config = {
              city: "",
              country: {
                id: "",
                label: ""
              },
            }
          }

        }*/
            list.push({
                id: i,
                tabName: orderPartie.orderParty.role?.resourceUid || OrderPartyRoleEnum.SELLER,
                role_code: orderPartie.orderParty.role?.resourceUid || OrderPartyRoleEnum.SELLER,
                showDeleteBtn: false,
                objectType: orderPartie.objectType,
                systemUid: orderPartie.systemUid,
                resourceUid: orderPartie.resourceUid,
                orderPartyResourceUid:orderPartie.orderParty.resourceUid,
                associatedParty: {
                  third: orderPartie.orderParty.third,
                },
              })
        }
    

    return list
}
async function buildAddressWithPostalCode(addresses: any) {
    const partyAddresses: any = []
    for (const partyAddress of addresses) {
        const result = await useApi(UON.SystemUid.odmParty, 'address').get(partyAddress.address.resourceUid);
        if (result.response) {
            const address = result.response
            partyAddress.address=address
            if(!partyAddress.address.type){
                partyAddress.address.type= {
                    type: {
                      value: [],
                    },
                    objectType: 'odm.party.addresstype',
                    resourceUid: ''
                  }
            }
            partyAddress.address.postalCode = await buildPostalCode(address.postalCode.resourceUid);
            partyAddress.livingSituation = {
                type: {
                    value: partyAddress.livingSituation?.resourceUid || partyAddress.livingSituation?.type.value,
                    label: partyAddress.livingSituation?.resourceUid?.toString() !== "" ? i18n.global.t("middleOffice.party.person.address.livingstatus." + partyAddress.livingSituation?.resourceUid) : partyAddress.livingSituation?.type.value !== "" ? i18n.global.t("middleOffice.party.person.address.livingstatus." + partyAddress.livingSituation?.type.value) : '',
                }
            };
            partyAddresses.push(partyAddress)
        } else console.error(`buildAddress => ${result.error}`)
    }
    return partyAddresses
}

export async function buildPostalCode(resourceUid: string) {
    const resultPostalCode = await useApi(UON.SystemUid.odmParty, 'frenchpostalcode').get(resourceUid);
    if (resultPostalCode.response) {
        const postalCode = resultPostalCode.response
        const countryName = i18n.global.t(`demand.party.country.${postalCode.country.resourceUid}`)
        return {
            ...postalCode,
            country: {
                ...postalCode.country,
                name: countryName
            },
            type: {
                value: postalCode.resourceUid,
                label: `${postalCode.city} - ${postalCode.postalCode}`,
                config: {
                    city: postalCode.city,
                    department: `${postalCode.department}`,
                    country: {
                        id: postalCode.country.resourceUid,
                        label: countryName
                    },
                }
            }
        }
    } else console.error(`postalCode => ${resultPostalCode.error}`)
}
export const orderDuplicateMode = async (resourceUid: string,number:any,length:any) => {
    const order = await duplicateOrder(resourceUid,number,length)
    return order
	}
    export  async function getAssociatedPartySeller(partyResourceUid:any){
        const type = partyResourceUid.objectType.includes("person")?'party-FrenchPerson':'party-FrenchOrganization'
        const third = await getThird(partyResourceUid.resourceUid,type,'')
          //  await buildAddressWithPostalCode(third.addresses)
            
            mountSelectFields(third, ['legalCategory', 'activitySector'])
        if (third.organization && third.organization.facilities) {
          for (const facility of third.organization.facilities) {
            if (!facility.address) {
              facility.address = { ...store.getters["thirdModule/getAddressInit"] }
            }
          }
        }
        if (third.person && third.person.birthplace && third.person.birthplace.type) {
          if (!third.person.birthplace.type.config) {
            third.person.birthplace.type.config = {
              city: "",
              country: {
                id: "",
                label: ""
              },
            }
          }

        }
        let role;
        if (third && third.roles && third.roles.length > 0){
             role = third.roles[0].role?.type?.value ? third.roles[0].role?.type?.value === "VENDOR" ? OrderPartyRoleEnum.SELLER : third.roles[0].role?.type?.value : OrderPartyRoleEnum.SELLER;
        }
        else{
             role = OrderPartyRoleEnum.SELLER;
        }
            return {
                id: 2,
                tabName: role,
                role_code: role,
                showDeleteBtn: false,
                objectType: "odm.order.orderassociatedparty",
                systemUid: "odm-order",
                resourceUid: "",
                orderPartyResourceUid:"",
                associatedParty: {
                  third: third,
                },
              }
    }
// ici
    export  function getPlaningEvent(filter:any) {
        return new Promise<any>(async (resolve, reject) => {
            const url = settings.api_query_url;
        
            const qc_header: any = {
              qc: {
                queryId: 'deliverySchedule-search-filter-backup',
              }
            }
            qc_header.qc.parameters = {
              ...filter
            }
            const operators = []
            const operatorsName = []
            operators.push(getOperator("user_daaq", "read_daaq", "LIKE", globalConfig.formatters.getEqualValue(store.state.authModule?.user?.daaqList[0]?.config?.readDAAQ + '%'), ""))
            if (filter && filter.filterReference) {
                operators.push(getOperator( "lower(order_order_item","external_reference)","LIKE",globalConfig.formatters.getEqualValue(filter.filterReference + "%").toLowerCase(),"external_reference"));
            }

            if (filter && filter.activity !== undefined && filter.activity.value!==undefined) {
              operators.push(getOperator("order_order_asset","flag_new","EQUAL",globalConfig.formatters.getEqualValue(filter.activity.value),"flag_new"));
            }
            if (filter && filter.filterStatus) {
                operators.push(getOperator("order_order","status_code","EQUAL",globalConfig.formatters.getEqualValue(filter.filterStatus),"status_code"));
            }
            if (filter && filter.filterGroup) {
                operators.push(getOperator("user_security_group", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.filterGroup), "id"))
                        }
            if (filter && filter.filterActor) {
                operators.push(getOperator("order_assigned_user","user_resourceuid","EQUAL",globalConfig.formatters.getEqualValue(filter.filterActor),"id"));
            }
            if (filter?.startDate) {
              operators.push(
                getOperator(
                  "order_order_planning",
                  "planning_date",
                  "GREATER_THAN_EQUAL",
                  globalConfig.formatters.getEqualValue(moment(filter.startDate,  'DD/MM/YYYY').format('YYYY-MM-DD')),
                  "planning_date"
                )
              );
          } 
          if (filter?.endDate) {
            operators.push(
              getOperator(
                "order_order_planning",
                "planning_date",
                "LESS_THAN_EQUAL",
                globalConfig.formatters.getEqualValue(moment(filter.endDate,  'DD/MM/YYYY').format('YYYY-MM-DD')),
                "planning_date"
              )
            );
        } 
            if (filter && filter.filterBrand && filter.filterBrand.label) {
                operators.push(
                    getOperator(
                      "order_order_asset",
                      "brand",
                      "LIKE",
                      globalConfig.formatters.getEqualValue("%" + filter.filterBrand.label + "%"),
                      "brand"
                    )
                  );            
            }
            if (filter && filter.filterModel && filter.filterModel.label) {
                operators.push(
                    getOperator(
                      "order_order_asset",
                      "_range",
                      "LIKE",
                      globalConfig.formatters.getEqualValue("%" + filter.filterModel.label + "%"),
                      "range"
                    )
                  );  
            }

            if(filter && filter.filterClient){
                let name = filter.filterClient.split(" ")[0]
                const filterValue = globalConfig.formatters.getEqualValue("%" + name + "%").toLowerCase();
                
                operatorsName.push(
                    getOperator(
                    "lower(party_party",
                    "family_name)",
                    "LIKE",
                    filterValue,
                    "family_name"
                  )
                );
                operatorsName.push(
                  getOperator(
                    "lower(party_party",
                    "first_name)",
                    "LIKE",
                    filterValue,
                    "first_name"
                  )
                );
                operatorsName.push(
                  getOperator(
                    "lower(party_party",
                    "_name)",
                    "LIKE",
                    filterValue,
                    "name"
                  )
                );
            }

            if (filter && filter.planningType) {
              operators.push(getOperator("order_order_planning_type","internal_code","EQUAL",globalConfig.formatters.getEqualValue(filter.planningType),"planningType"));
              if (filter && filter.category) {
                operators.push(getOperator("order_order_planning_type","id","EQUAL",globalConfig.formatters.getEqualValue(filter.category),"category"));
              }
            }

            operators.push(
              getOperator("order_order_asset", "asset_trade_in_id", "ISNULL")
            );

            /*if(filter && filter.filterRole && filter.filterRole === 'SELLER'){
                operators.push(getInOperator("order_order_party", "role_code", "IN", null, null, globalConfig.formatters.getInValue(['SELLER','CUSTOMER'])))
                operators.push(
                    getOperator(
                      "user_lease_forge_user1",
                      "id",
                      "EQUAL",
                      globalConfig.formatters.getEqualValue(filter.filterUser),
                      "id"
                    )
                  );  

            }*/
            //if(filter && !filter.filterRole){
                operators.push(
                    getOperator(
                        "order_order_party",
                        "role_code",
                        "EQUAL",
                        "'CUSTOMER'",
                        "role_code"
                    )
                );
            //}

            //operators.push(getInOperator("order_order", "status_code", "IN", null, null, globalConfig.formatters.getInValue(['DELIVERED','PLANNED','TO_PLAN','TO_BE_RESCHEDULED'])))
            if ( operatorsName.length> 0 ){

                    operators.push( { or: {
                    operators :[... operatorsName]
                    } 
                })
            }
            if (operators.length > 0) {
              qc_header.qc.otherFilters = {
                expressions: [
                  {
                    and: {
                      operators
                    }
                  }
                ]
              }
              qc_header.qc.otherOrders = null
              qc_header.qc.offset = 0
              qc_header.qc.limit = 50
            }
        
            const _headers = headers({
              qc: JSON.stringify(qc_header.qc)
            })
        
            axios.get(url, {
              headers: _headers
            }).then(async res => {
        
            
              const response : any[] =[]
                 for (const resItem of res.data){
              
                const  date = resItem.planning_date ? convertDateFromDB (resItem.planning_date):resItem.initialdeliverydate ? convertDateFromDB (resItem.initialdeliverydate): formatDate(moment(new Date()).format("DD/MM/YYYY"));
                const startDate=  resItem.planning_date||resItem.initialdeliverydate ? date :date +' 09:00'
                const endDate =  resItem.planning_date ? convertDateFromDB (startDate,"endDate"):resItem.initialdeliverydate ? convertDateFromDB (resItem.initialdeliverydate,"endDate") : date +' 11:00'
                let associatedparty = ''
                
                if (resItem.associatedpartyfirstname) {
                    associatedparty = associatedparty + resItem.associatedpartyfirstname + ' '
                } if (resItem.associatedpartyfamilyname) {
                    associatedparty = associatedparty + resItem.associatedpartyfamilyname
                }
                if (resItem.associatedpartyname) {
                    associatedparty = resItem.associatedpartyname
                }
                const flagNew = resItem.flagnew ? i18n.global.t("asset.vehicleType.vn") : i18n.global.t("asset.vehicleType.vo")     
               const orderItem = await  getOrderItem(resItem.idorderitems)
                const brand = orderItem && orderItem.data.orderAssets[0].brand 
                        ? orderItem.data.orderAssets[0].brand.replace(/asset.good.vehicle.brand./g, "") : ""
                const  range= orderItem && orderItem.data.orderAssets[0]&&orderItem.data.orderAssets[0].range.includes('range.') 
                        ? orderItem.data.orderAssets[0].range.substring(orderItem.data.orderAssets[0].range.lastIndexOf(".") + 1) :
                        orderItem && orderItem.data.orderAssets[0]&&orderItem.data.orderAssets[0].range
                
                        const   productAsset=  orderItem &&  orderItem.data.orderAssets[0].assetProduct ? orderItem.data.orderAssets[0].assetProduct?.resourceUid : ""
                const   description    =  orderItem &&  orderItem.data.orderAssets[0].description ? orderItem.data.orderAssets[0].description: ""
                const accessoryList = orderItem.data.orderAssets[0].orderAssetComponents
                                    .filter((item: any) => item.type.resourceUid === "ACCESSORY")
                                    .map((item: any) => item.label)
                                    .join(", ");
                const comment = resItem.matriculation ? resItem.chassisreference ? resItem.matriculation + ' : ' + resItem.chassisreference + '<br>'
                : resItem.matriculation + '<br>' : resItem.chassisreference ? resItem.chassisreference + '<br>' : ""
                const chassisreference = resItem.chassisreference ? resItem.chassisreference  : ""
                const matriculation = resItem.matriculation ? resItem.matriculation  : ""
                const event:any={
                  id: resItem.idorderitems,
                  start: startDate,
                  end: endDate,
                  title: resItem.externalreference,
                  color: setPlanningTypeColor(resItem.planning_type_internal_code),
                  deletable: true,
                  orderid: resItem.orderid,
                  content:resItem.assigned_user_first_name+' '+resItem.assigned_user_last_name + '<br>' + '<i class="fas fa-truck"> '+associatedparty +' : '+brand+' '+ range +' '+ flagNew + '</i>'+ '<br>' + chassisreference + '<br>' + matriculation,
                  associatedparty:  associatedparty,
                  salespersonfirstname:  resItem.salespersonfirstname,
                  salespersonlastname:  resItem.salespersonlastname,
                  ordercreateddate:  resItem.ordercreateddate,
                  initialdeliverydate:  resItem.initialdeliverydate,
                  effectivedeliverydate:  resItem.effectivedeliverydate,
                  previsiondeliverydate:  resItem.planning_date,
                  status_code:  resItem.status_code,
                  _daaq: resItem._daaq,
                  class: "vuecal" + resItem.order_planning_id,
                  clientName: associatedparty,
                  productAsset: productAsset,
                  description: description,
                  brand : brand,
                  range: range,
                  resourceUid:resItem.order_planning_id,
                  planningType:resItem.planning_type_internal_code,
                  category:resItem.planning_type_id,
                  confirmed:resItem.flag_confirmed,
                  orderAssignedUserId:resItem.order_assigned_user_id,
                  flagNew:resItem.flagnew ? "asset.vehicleType.vn" : "asset.vehicleType.vo",
                  matriculation: resItem.matriculation,
                  isAssettradein: orderItem.data.orderAssets.length > 1,
                  enginetype: resItem.enginetype,
                  referenceOrder: resItem.referenceorder,
                  activity: resItem.flagnew,
                  comment: resItem.planningcomment,
                  assignedUserName: resItem.assigned_user_first_name+' '+resItem.assigned_user_last_name,
                  accessory: accessoryList,
                  assignedSalesPerson: resItem.assignedsalesperson,

                }
                if(resItem.order_assigned_user_id){
                  event.assignedUser={
                    value:resItem.user_lease_order_assigned_user_id,
                    label:resItem.assigned_user_first_name+' '+resItem.assigned_user_last_name
                  }
                }
                response.push(event)
                }
              
              resolve(response)
        
            }).catch(err => {
                console.error(err)
            });
        
        
          })

    }
    export  function getPlaningEventExport(filter:any) {
        return new Promise<any>(async (resolve, reject) => {
            const url = settings.api_query_url;
        
            const qc_header: any = {
              qc: {
                queryId: 'deliverySchedule-search-filter-export',
              }
            }
            qc_header.qc.parameters = {
              ...filter
            }
            const operators = []
            const operatorsName = []
            if (filter && filter.filterReference) {
                operators.push(getOperator( "lower(order_order","_reference)","LIKE",globalConfig.formatters.getEqualValue(filter.filterReference + "%").toLowerCase(),"reference"));
            }
            if (filter && filter.filterStatus) {
                operators.push(getOperator("order_order","status_code","EQUAL",globalConfig.formatters.getEqualValue(filter.filterStatus),"status_code"));
            }
            if (filter && filter.filterGroup) {
                operators.push(getOperator("user_security_group", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.filterGroup), "id"))
                        }
            if (filter && filter.filterActor) {
                operators.push(getOperator("user_lease_forge_user","id","EQUAL",globalConfig.formatters.getEqualValue(filter.filterActor),"id"));
            }
            if (filter && filter.filterBrand && filter.filterBrand.id) {
                operators.push(
                    getOperator(
                      "order_order_asset",
                      "brand",
                      "EQUAL",
                      globalConfig.formatters.getEqualValue(filter.filterBrand.id),
                      "brand"
                    )
                  );            
            }
            if (filter && filter.filterModel && filter.filterModel.id) {
                operators.push(
                    getOperator(
                      "order_order_asset",
                      "_range",
                      "EQUAL",
                      globalConfig.formatters.getEqualValue(filter.filterModel.id),
                      "range"
                    )
                  );  
            }

            if(filter && filter.filterClient){
                let name = filter.filterClient.split(" ")[0]
                const filterValue = globalConfig.formatters.getEqualValue("%" + name + "%").toLowerCase();
                
                operatorsName.push(
                    getOperator(
                    "lower(party_party",
                    "family_name)",
                    "LIKE",
                    filterValue,
                    "family_name"
                  )
                );
                operatorsName.push(
                  getOperator(
                    "lower(party_party",
                    "first_name)",
                    "LIKE",
                    filterValue,
                    "first_name"
                  )
                );
                operatorsName.push(
                  getOperator(
                    "lower(party_party",
                    "_name)",
                    "LIKE",
                    filterValue,
                    "name"
                  )
                );
            }

            /*if(filter && filter.filterRole && filter.filterRole === 'SELLER'){
                operators.push(getInOperator("order_order_party", "role_code", "IN", null, null, globalConfig.formatters.getInValue(['SELLER','CUSTOMER'])))
                operators.push(
                    getOperator(
                      "user_lease_forge_user1",
                      "id",
                      "EQUAL",
                      globalConfig.formatters.getEqualValue(filter.filterUser),
                      "id"
                    )
                  );  

            }*/
            //if(filter && !filter.filterRole){
                operators.push(
                    getOperator(
                        "order_order_party",
                        "role_code",
                        "EQUAL",
                        "'CUSTOMER'",
                        "role_code"
                    )
                );
            //}

            operators.push(getInOperator("order_order", "status_code", "IN", null, null, globalConfig.formatters.getInValue(['DELIVERED','PLANNED','TO_PLAN','TO_BE_RESCHEDULED'])))
            if ( operatorsName.length> 0 ){

                    operators.push( { or: {
                    operators :[... operatorsName]
                    } 
                })
            }
            if (operators.length > 0) {
              qc_header.qc.otherFilters = {
                expressions: [
                  {
                    and: {
                      operators
                    }
                  }
                ]
              }
              qc_header.qc.otherOrders = null
              qc_header.qc.offset = 0
              qc_header.qc.limit = 50
            }
        
            const _headers = headers({
              qc: JSON.stringify(qc_header.qc)
            })
        
            axios.get(url, {
              headers: _headers
            }).then(async res => {
        
            
              const response : any[] =[]
                 for (const resItem of res.data){
              
                const  date = resItem.previsiondeliverydate ? convertDateFromDB (resItem.previsiondeliverydate):resItem.initialdeliverydate ? convertDateFromDB (resItem.initialdeliverydate): formatDate(moment(new Date()).format("DD/MM/YYYY"));
                const startDate=  resItem.previsiondeliverydate||resItem.initialdeliverydate ? date :date +' 09:00'
                const endDate =  resItem.previsiondeliverydate ? convertDateFromDB (resItem.previsiondeliverydate,"endDate"):resItem.initialdeliverydate ? convertDateFromDB (resItem.initialdeliverydate,"endDate") : date +' 11:00'
                let associatedparty = ''
                let accessory = ''
                if (resItem.associatedpartyfirstname) {
                    associatedparty = associatedparty + resItem.associatedpartyfirstname + ' '
                } if (resItem.associatedpartyfamilyname) {
                    associatedparty = associatedparty + resItem.associatedpartyfamilyname
                }
                if (resItem.associatedpartyname) {
                    associatedparty = resItem.associatedpartyname
                }     
               const orderItem = await  getOrderItem(resItem.idorderitems)
                const brand = orderItem && orderItem.data.orderAssets[0].brand 
                        ? orderItem && orderItem.data.orderAssets[0].brand.replace(/asset.good.vehicle.brand./g, "") : ""
                const  range= orderItem && orderItem.data.orderAssets[0].range.includes('range.') 
                        ? orderItem.data.orderAssets[0].range.substring(orderItem.data.orderAssets[0].range.lastIndexOf(".") + 1) : ""
                
                        const   productAsset=  orderItem &&  orderItem.data.orderAssets[0].assetProduct ? orderItem.data.orderAssets[0].assetProduct?.resourceUid : ""
                const   description    =  orderItem &&  orderItem.data.orderAssets[0].description ? orderItem.data.orderAssets[0].description: ""
                if (resItem.accessory) {
                    const accessories =  JSON.parse(resItem.accessory);
                    accessory = accessories.length ===1 && accessories[0] === null 
                    ? '' 
                    : accessories.filter((item:any) => item !== null).join(', ');
                }  
       
         
                   response.push({
                        id: resItem.idorderitems,
                        start: startDate,
                        end: endDate,
                        title: resItem.referenceorder,
                        color: resItem.color ? resItem.color : setStatusColor(resItem.status_code) ,
                        deletable: true,
                        orderid: resItem.orderid,
                        content:'<i class="fas fa-truck"> '+associatedparty +' : '+brand+' '+ range + '</i>' ,
                        associatedparty:  associatedparty,
                        salespersonfirstname:  resItem.salespersonfirstname,
                        salespersonlastname:  resItem.salespersonlastname,
                        ordercreateddate:  resItem.ordercreateddate,
                        initialdeliverydate:  resItem.initialdeliverydate,
                        effectivedeliverydate:  resItem.effectivedeliverydate,
                        previsiondeliverydate:  resItem.previsiondeliverydate,
                        status_code:  resItem.status_code,
                        _daaq: resItem._daaq,
                        class: "vuecal" + resItem.idorderitems,
                        clientName: associatedparty,
                        productAsset: productAsset,
                        description: description,
                        brand : brand,
                        range: range,
                        accessory: accessory,
                        referenceOrder: resItem.referenceorder,
                        activity: resItem.activity,
                        matriculation: resItem.matriculation,
                        idassettradein: resItem.idassettradein                   
                  })
                }
              
              resolve(response)
        
            }).catch(err => {
                console.error(err)
            });
        
        
          })

    }


   function setStatusColor(status: string) {
        switch (status) {
            
          case OrderStatusEnum.PLANNED:
            return "#3699ff";
          case OrderStatusEnum.DELIVERED:
            return "#07fa2c";
          case OrderStatusEnum.TO_PLAN:
            return "#fa0707";
            case OrderStatusEnum.TO_BE_RESCHEDULED:
            return "#e607fa";
          default:
            return "#3699ff";
        }
      }

      function setPlanningTypeColor(status: string) {
        switch (status) {
            
          case "PREPARATION":
            return "#005AFF";
          default:
            return "#20C9A3";
        }
      }

    function  convertDateFromDB(dateFromDB: any, enddate?:any){
        const dateOrigine = new Date(dateFromDB);
        const formattedTime = moment(dateFromDB).format('HH:mm');
        const add2Hours = enddate ? moment(dateOrigine.setHours(dateOrigine.getHours() + 2)).format('HH:mm') : moment(dateOrigine.setHours(dateOrigine.getHours())).format('HH:mm');

        let hour=  ""
        const jour = dateOrigine.getDate();
        const mois = dateOrigine.getMonth() + 1; // Les mois commencent à partir de 0, donc on ajoute 1
        const année = dateOrigine.getFullYear();

        if (dateOrigine.getHours() === 0 && dateOrigine.getMinutes() === 0 && dateOrigine.getSeconds() === 0) {
           hour=  "09:00"// Modifier l'heure à 9h du matin
           if (enddate){
            hour=  "11:00"
            }
        }
        else {
            hour=  formattedTime
            if (enddate){
                hour=  add2Hours
                }
        }
        const formatDate  =  année + '-' + (mois < 10 ? '0' : '') + mois + '-' + (jour < 10 ? '0' : '') + jour + ' ' +hour;
        return formatDate

      }


      export function getOrderAlertList(filter: any): Promise<Picklist> {
        return new Promise<Picklist>(async (resolve, reject) => {
            const url = settings.api_query_url;
   
            const qc_header: any = {
                qc: {
                    queryId: 'order-alert-reason',
                }
            }
            qc_header.qc.parameters = {
                ...filter
            }
            const operators = []
           
            if (filter && filter.orderId != null) {
                operators.push(getOperator("order_order_alert", "order_id", "LIKE", globalConfig.formatters.getLikeValue(filter.orderId), "id"))
            }

            if (store.state.orderModule.showDeletedAlerts !== null && store.state.orderModule.showDeletedAlerts !== undefined) {
              operators.push(
                getOperator(
                  "order_order_alert",
                  "alert_validity_until",
                  store.state.orderModule.showDeletedAlerts === true ? "ISNOTNULL" : "ISNULL"
                )
              );
            }

            if (operators.length > 0) {
                qc_header.qc.otherFilters = {
                    expressions: [
                        {
                            and: {
                                operators
                            }
                        }
                    ]
                }
                qc_header.qc.otherOrders = null
                qc_header.qc.offset = 0
                qc_header.qc.limit = 50
            }
   
            const _headers = headers({
                qc: JSON.stringify(qc_header.qc)
            })
   
            axios.get(url, {
                headers: _headers
            }).then(res => {
                const response = {
                    systemUid: 'odm-party',
                    items: res.data.map((resItem: any) => {
                        return {
    
                            id: resItem.id,
                            comment: resItem.alert_comment,
                            created_by: resItem._who_created,
                            created_when : resItem._when_created,
                            reason_code: resItem.reason_code,
                            alert_validity_from: resItem.alert_validity_from,
                            alert_validity_until: resItem.alert_validity_until,
                        }
                    })
   
                }
                resolve(response)
            }).catch(err => {
                //FIXME: until debizium is fixed
                //reject(err)
   
            });
   
   
        })
    }



    export function getAlertReasons(): Promise<Picklist> {
        return new Promise<Picklist>(async (resolve, reject) => {
            const url = settings.api_query_url;
   
            const qc_header: any = {
                qc: {
                    queryId: 'alert-reason',
                }
            }
            
            const operators :any  = []
           
             
            if (operators.length > 0) {
                qc_header.qc.otherFilters = {
                    expressions: [
                        {
                            and: {
                                operators
                            }
                        }
                    ]
                }
                qc_header.qc.otherOrders = null
                qc_header.qc.offset = 0
                qc_header.qc.limit = 50
            }
   
            const _headers = headers({
                qc: JSON.stringify(qc_header.qc)
            })
   
            axios.get(url, {
                headers: _headers
            }).then(res => {
                const response = {
                    systemUid: 'odm-party',
                    items: res.data.map((resItem: any) => {
                        return {
                            id: resItem.id,
                            label: resItem.id
                        }
                    })
   
                }
                resolve(response)
            }).catch(err => {
                //FIXME: until debizium is fixed
                //reject(err)
   
                
            });
   
   
        })
    }
    export function buildAlertList(alertList:  any){
        const orderAlert: any = {};
        alertList.items?.forEach((item: any) => {
          if (!orderAlert[item.id]) {
            orderAlert[item.id] = {
              id: item.id,
              whoCreated: item.created_by,
              whenCreated: item.created_when,
              comment: item.comment,
              alertValidity: {
                from: item.alert_validity_from,
                until: item.alert_validity_until, 
              },
              reasons: [],
            };
          }
          orderAlert[item.id].reasons.push(item.reason_code);
        });
        // Convert the reasons array to a comma-separated string
        Object.values(orderAlert).forEach((alert: any) => {
          alert.reasons = alert.reasons.join(",");
        });
        //return this.rows=  res.items
        return Object.values(orderAlert) || [];
      
    }


    const handleOrderAssets = async (orderItem: OrderItem,orderAsset: any) => {

        delete orderAsset.config
        if (orderAsset.assetDelivery) {
            const assetDelivery = await saveAssetDelivery(deepClone(orderAsset.assetDelivery))
            orderAsset.assetDelivery = {
                resourceUid: assetDelivery.resourceUid,
                objectType: assetDelivery.objectType,
                systemUid: assetDelivery.systemUid
            }
        }
        if (orderAsset.assetTradeIn) {
            const assetTradeIn = await saveAssetTradeIn(orderAsset.assetTradeIn)
            orderAsset.assetTradeIn = {
                resourceUid: assetTradeIn.resourceUid,
                objectType: assetTradeIn.objectType,
                systemUid: assetTradeIn.systemUid
            }
        }
        //

        //
        /*if(orderItem.effectiveDeliveryDate){
            orderItem.effectiveDeliveryDate =  moment(new Date(orderItem.effectiveDeliveryDate)).format('YYYY-MM-DD HH:mm:ss')
        }
        if(orderItem.initialDeliveryDate){
            orderItem.initialDeliveryDate =  convertDateHour(orderItem.initialDeliveryDate)

        }
        if(orderItem.previsionDeliveryDate){
            orderItem.previsionDeliveryDate =  convertDateHour(orderItem.previsionDeliveryDate)
        }*/
        if (orderAsset.supplier && orderAsset.supplier?.party?.resourceUid) {
            const supplier = orderAsset.supplier
            if(supplier.third){
                delete supplier.third
            }

            if (supplier.resourceUid === '') {
                delete supplier.resourceUid
            }
            const party = await createOrderParty(supplier)
            orderAsset.supplier = {
                resourceUid: party.resourceUid,
                objectType: 'odm.order.orderparty',
                systemUid: 'odm-order',
            }
        } else {
            delete orderAsset.supplier
        }
        if(orderAsset.orderAssetServices){
            orderAsset.orderAssetServices= handleOrderAssetServices(orderAsset.orderAssetServices)
        }
        if ((orderAsset as any).inventoryItemCode) {
            orderAsset.assetInventory= {
                resourceUid: (orderAsset as any).inventoryItemCode,
                objectType: 'odm.product.inventoryitem.inventoryitemvehicle.inventoryitemcar',
                systemUid: 'odm-product',
            }
            delete (orderAsset as any).inventoryItemCode
        }
        if(orderAsset.orderAssetComponents&&orderAsset.orderAssetComponents.length>0){
            for(const orderAssetComponent of orderAsset.orderAssetComponents){
                delete orderAssetComponent.config
                delete orderAssetComponent.taxValue
            }

        }
        if(orderAsset.brand &&orderAsset.brand.id){
            orderAsset.brand=orderAsset.brand.id
        }

        if(orderAsset.range &&orderAsset.range.id){
            orderAsset.range=orderAsset.range.id
        }

        if(orderAsset.version &&orderAsset.version.id){
            orderAsset.version=orderAsset.version.id
        }

        if(orderAsset.carBodyStyle &&orderAsset.carBodyStyle.id){
            orderAsset.carBodyStyle=orderAsset.carBodyStyle.id
        }

        if(orderAsset.engineType &&orderAsset.engineType.id){
            orderAsset.engineType=orderAsset.engineType.id
        }
        delete orderAsset.proposalAssetItems
        delete orderAsset.doorNumbers
        delete orderAsset.Kilometrage

}


  function handleBodyForSaveCar(orderAssetTradeIn:any,referenceItem:any){
        const carBody :any = {
            type:{resourceUid:'CAR'},
            nbOfDoor: orderAssetTradeIn.doorNumbers,
            horsePower: orderAssetTradeIn.horsePower,
            classifications:[],

            reference:referenceItem+' '+ orderAssetTradeIn.brand.label+' '+orderAssetTradeIn.range.label,
            name:referenceItem+' '+ orderAssetTradeIn.brand.label+' '+orderAssetTradeIn.range.label ,
            validity: {
              from: "2001-06-06",
              until: null
            },

            status: {
                objectType: 'odm.product.productstatus',
                systemUid: 'odm-product',
                resourceUid: 'DRAFT'
            },
            feature: [
              {
                defaultValue: false,
                label: 'AMOUNT',
                internalCode: 'AMOUNT',
                name: 'AMOUNT',
                standardValue: orderAssetTradeIn.amountWoTax.amount,
                minValue: 0.0,
                maxValue: 0.0,
                currency: 'EUR',
                objectType: 'odm.product.featurepricingparameter',
                systemUid: 'odm-product',
                validity:{from:"2001-06-06",until:null}
              }
            ],
            objectType: 'odm.product.productfeature.product.assetproduct.goodproduct.vehicleproduct.carproduct',
            systemUid:'odm-product'
          }
          if(orderAssetTradeIn.brand &&orderAssetTradeIn.brand.id){
            carBody.brand={id:orderAssetTradeIn.brand.id,
                config: {name: 'asset.good.vehicle.brand'}
            }
          }
          if(orderAssetTradeIn.range &&orderAssetTradeIn.range.id){
            carBody.range={id:orderAssetTradeIn.range.id,
                config: {name: 'asset.good.vehicle.brand.range'}
            }
          }
          if(orderAssetTradeIn.version &&orderAssetTradeIn.version.id){
            carBody.version=orderAssetTradeIn.version

          }
          if(orderAssetTradeIn.carBodyStyle &&orderAssetTradeIn.carBodyStyle.id){
            carBody.carBodyStyle=orderAssetTradeIn.carBodyStyle
          }
          if(orderAssetTradeIn.engineType &&orderAssetTradeIn.engineType.id){
            carBody.engineType=orderAssetTradeIn.engineType
          }
          return carBody

      }



   
      export const calculateMargin = async (order: Order) => {
        let  payload
       if (order.resourceUid) {
         payload = await getOrder(order.resourceUid)
         const OrderState = deepClone(order)
       if (OrderState?.orderItems){
             for(const item  of   OrderState?.orderItems) {

                    if(item.effectiveDeliveryDate){
                        item.effectiveDeliveryDate =  moment(item.effectiveDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                    }
                    if(item.customerAppointmentDate){
                      item.customerAppointmentDate = moment(item.customerAppointmentDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                    }
                    if(item.initialDeliveryDate){
                        //item.initialDeliveryDate =  item.initialDeliveryDate ? moment(item.initialDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : null
                        const frenchDate = moment(item.initialDeliveryDate, 'DD/MM/YYYY HH:mm:ss', true); 
                        const dateWithoutSeconds = moment(item.initialDeliveryDate, 'DD/MM/YYYY HH:mm', true); 

                        if (frenchDate.isValid()) {
                            item.initialDeliveryDate = moment(item.initialDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                        }
                        if (dateWithoutSeconds.isValid()) {
                          item.initialDeliveryDate = moment(item.initialDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
                      }
                      }
                    if(item.previsionDeliveryDate){
                        //item.previsionDeliveryDate =  moment(item.previsionDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                        const frenchDate = moment(item.previsionDeliveryDate, 'DD/MM/YYYY HH:mm:ss', true); 
                        const dateWithoutSeconds = moment(item.previsionDeliveryDate, 'DD/MM/YYYY HH:mm', true); 

                        if (frenchDate.isValid()) {
                            item.previsionDeliveryDate = moment(item.previsionDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                        }
                        if (dateWithoutSeconds.isValid()) {
                          item.previsionDeliveryDate = moment(item.previsionDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
                      }
                    }

                    if (item.type){
                        deleteNonRequiredAttributes(item.type, ["objectType", "resourceUid", "system_uid"]);
                    }
                    if (item.status){
                        deleteNonRequiredAttributes(item.status, ["objectType", "resourceUid", "system_uid"]);
                    }

                    if (item.orderAssets) {
                        const orderAsset = item.orderAssets[0]
                        delete orderAsset.config
                        delete orderAsset.taxValue
                        delete  orderAsset.supplier
                        delete orderAsset.assetTradeIn
                        delete orderAsset.assetDelivery
                        delete orderAsset.matriculationDate
                        delete orderAsset.flagNew
                        delete orderAsset.flagVD
                        delete orderAsset.assetProduct?.rawData

                        if(orderAsset.firstCarRegistrationDate){
                          orderAsset.firstCarRegistrationDate = moment(orderAsset.firstCarRegistrationDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                        }
                        
                        delete orderAsset.packAssetProduct?.rawData
                        item.orderAssets.splice(1, 1);
                        if(orderAsset.orderAssetComponents&&orderAsset.orderAssetComponents.length>0){
                            for(const orderAssetComponent of orderAsset.orderAssetComponents){
                                delete orderAssetComponent.config
                                delete orderAssetComponent.type.daaq
                                delete orderAssetComponent.type.businessData
                              
                            }
                        }
                        if(orderAsset.orderAssetServices&&orderAsset.orderAssetServices.length>0){
                            for(const orderAssetService of orderAsset.orderAssetServices){
                                delete orderAssetService.type
                                delete orderAssetService.csLibelleBaremeServices
                                delete orderAssetService.szCodeBaremeServices
                            }
                        }
                        if(orderAsset.availabilityCarDate){
                          orderAsset.availabilityCarDate = moment(orderAsset.availabilityCarDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                        }
                        if(orderAsset.billingCarDate){
                          orderAsset.billingCarDate = moment(orderAsset.billingCarDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                        }
                        if(orderAsset.initialDeliveryFactoryDate){
                          orderAsset.initialDeliveryFactoryDate = moment(orderAsset.initialDeliveryFactoryDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                        }
                        if(orderAsset.effectiveDeliveryFactoryDate){
                          orderAsset.effectiveDeliveryFactoryDate = moment(orderAsset.effectiveDeliveryFactoryDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                        }
                        if(orderAsset.probablyDeliveryFactoryDate){
                          orderAsset.probablyDeliveryFactoryDate = moment(orderAsset.probablyDeliveryFactoryDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                        }
                    }
                    if(item.orderAssignmentDate){
                      item.orderAssignmentDate = moment(item.orderAssignmentDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                    }
           

                }
         }
                payload.orderItems = OrderState.orderItems

        if (OrderState.orderCommissions  && OrderState.orderCommissions.length>0) {
                    for (const commission of OrderState.orderCommissions){
                        delete commission.role
                       if(commission.adjustAmount){
                          commission.adjustAmount={
                              amount: typeof commission.adjustAmount === 'string' ? commission.adjustAmount : commission.adjustAmount.amount,
                              currency:"EUR"
                              }
                            }
                              if(commission.accessoryProduct ){
                                deleteNonRequiredAttributes(commission.accessoryProduct, ["objectType", "resourceUid", "system_uid"]);
                            }
                            if(commission.orderAccessoryCalculationMethodOption){
                                deleteNonRequiredAttributes(commission.orderAccessoryCalculationMethodOption, ["objectType", "resourceUid", "system_uid"]);
                            }
                        }
         }
                
                payload.orderCommissions = OrderState.orderCommissions
                payload.shippingFees = OrderState.shippingFees
                payload.subventions = OrderState.subventions
                if(payload.effectiveDeliveryDate){
                 payload.effectiveDeliveryDate = moment(payload.effectiveDeliveryDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                }
                if(payload.previsionDeliveryDate){
                    payload.previsionDeliveryDate = moment(payload.previsionDeliveryDate,  'DD/MM/YYYY').format('YYYY-MM-DD')
                }          
       }
       const _payload = deepClone(payload)
       _payload.orderCommissions.forEach((item: any) => {
            delete item?.beneficiary?.associatedParty
            delete item?.beneficiary?.businessData
            delete item?.beneficiary?.daaq
       })
        const marginList =(await calculateMarginAPI(_payload)).data
        return marginList
    }

    export const saveMargin= (payload : any, marginList: any ) =>{
        const keys = {
            grossMargin: 'DistributionMargin',
            grossMarginLeft: 'grossMarginLeft',
            netMargin: 'netMargin',
            netMarginLeft: 'netMarginLeft'
        } as const;
        marginList.forEach((item: any) => {
            (Object.keys(keys) as Array<keyof typeof keys>).forEach((key) => {
                if (item.key === keys[key]) {
                    payload[key] = {
                        amount: item.value,
                        currency: 'EUR'
                    };
                }
            });
        });
    
        return payload;
    }
    export function buildOrderAlert(comment:string,reasons: any) {

        const listReasonAlert: any = []
        const orderAlert =
            {
                objectType: "odm.order.orderalert",
                systemUid: "odm-order",
                alertComment: comment,
                reasons: [],
                alertValidity: {
                  "from": moment(new Date()).format('YYYY-MM-DD'),
                  "until": null
                },
            }
        for (const reason of reasons) {
            const reasonAlert =
                {
                    objectType: "odm.order.orderalertreason",
                    systemUid: "odm-order",
                    reason: {
                        resourceUid: reason
                    }
                }
                listReasonAlert.push(reasonAlert)
        }
        orderAlert.reasons=listReasonAlert
        return orderAlert
    }

    export function buildOrderAlertToDelete(payload:any, alertId:string, comment:string,reasons: any) {
        const listReasonAlert: any = []
        const orderAlert = {
            resourceUid: alertId,
            objectType: "odm.order.orderalert",
            systemUid: "odm-order",
            alertComment: comment,
            reasons: [],
            alertValidity: {
              "from": payload.alert.alertValidity.from,
              "until": moment(new Date()).format('YYYY-MM-DD')
            },
        }
        for (const reason of reasons.split(",")) {
            const reasonAlert =
                {
                    objectType: "odm.order.orderalertreason",
                    systemUid: "odm-order",
                    reason: {
                        resourceUid: reason
                    }
                }
                listReasonAlert.push(reasonAlert)
        }
        orderAlert.reasons=listReasonAlert
        return orderAlert
    }

    export function getVendorsList(filter: any): Promise<Picklist> {
        return new Promise<Picklist>(async (resolve, reject) => {
          const url = settings.api_query_url;
      
          const qc_header: any = {
            qc: {
              queryId: "picklist-vendors-list",
            },
          };
          qc_header.qc.parameters = {
            ...filter,
          };
          const operators = [];
          if (filter && filter.role_code != null) {
            operators.push(
              getOperator(
                "party_party_role",
                "role_code",
                "EQUAL",
                globalConfig.formatters.getEqualValue(filter.role_code),
                "role_code"
              )
            );
          }
          if (filter && filter.network_node_id != null) {
            operators.push(
              getOperator(
                "party_party_role_network",
                "network_node_id",
                "EQUAL",
                globalConfig.formatters.getEqualValue(filter.network_node_id),
                "network_node_id"
              )
            );
          }
          if (operators.length > 0) {
            qc_header.qc.otherFilters = {
              expressions: [
                {
                  and: {
                    operators,
                  },
                },
              ],
            };
            qc_header.qc.otherOrders = null;
            qc_header.qc.offset = 0;
            qc_header.qc.limit = 50;
          }
      
          const _headers = headers({
            qc: JSON.stringify(qc_header.qc),
          });
      
          axios
            .get(url, {
              headers: _headers,
            })
            .then((res) => {
              const response = {
                systemUid: "odm-party",
                items: res.data.map((resItem: any) => {
                  return {
                    id: resItem.id,
                    label: resItem.family_name +' '+resItem.first_name,
                    config: {
                        vendor_id: resItem.id
                    }
                  };
                }),
              };
              resolve(response);
            })
            .catch((err) => {
                Notify.create({
                  message: `Query returned an error \n ${err}`,
                  color: "negative",
                });
              });
        });
      }

      async function handelDocumentsAsset(orderItems:any){
        for (const orderItem of orderItems) {
            for (const orderAsset of orderItem.orderAssets) {
                let filter : any
                if(orderAsset.assetProduct&&orderAsset.assetProduct.rawData){
                    for(const document of orderAsset.assetProduct.rawData.documents){
                        if(document.fileReference&&document.fileReference.resourceUid){
                            const fileReference = await getEntity("odm-filemngt", "file", document.fileReference.resourceUid);
                            document.fileReference.rawData=fileReference
                            if (orderAsset.assetProduct.resourceUid){
                                filter = {
                                   productId: orderAsset.assetProduct.resourceUid,
                                   documentId:document.resourceUid
                               }
                               const documentInfo = await getProducDocument(filter)
                               const addedBy = documentInfo.length ? documentInfo[0].first_name + ' ' + documentInfo[0].last_name : ""
                               document.addedBy = addedBy
                               const creationDate = documentInfo.length ? moment(documentInfo[0]._when_created, "YYYY-MM-DDTHH:mm:ss.SSSSSS").format('DD/MM/YYYY') : ""
                               document.creationDate = creationDate
                           }
                        }
                    }
                  }
            }
           }
      }
      async function handelDocumentsParty(orderPartys:any){
        for (const party of orderPartys) {
                let filter : any
                if(party.orderParty && party.orderParty.party && party.orderParty.party.rawData && party.orderParty.party.rawData.responseData){
                    for(const document of party.orderParty.party.rawData.responseData.documents){
                        if(document.fileReference&&document.fileReference.resourceUid){
                                filter = {
                                    partyId: party.orderParty.party.resourceUid,
                                    documentId:document.resourceUid
                                }
                               const documentInfo = await getPartyDocument(filter)
                               const addedBy = documentInfo.length ? documentInfo[0].first_name + ' ' + documentInfo[0].last_name : ""
                               document.addedBy = addedBy
                               const creationDate = documentInfo.length ? moment(documentInfo[0]._when_created, "YYYY-MM-DDTHH:mm:ss.SSSSSS").format('DD/MM/YYYY') : ""
                               document.creationDate = creationDate
                        }
                    }
                  }
            
           }
      }
      export function getVendorsRows(filter: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          const url = settings.api_query_url;
      
          const qc_header: any = {
            qc: {
              queryId: "picklist-vendors-list",
            },
          };
          qc_header.qc.parameters = {
            ...filter,
          };
          const operators = [];        
          if (filter && filter.role_code != null) {
            operators.push(
              getOperator(
                "party_party_role",
                "role_code",
                "EQUAL",
                globalConfig.formatters.getEqualValue(filter.role_code),
                "role_code"
              )
            );
          }
          if (filter && filter.network_node_id != null) {
            operators.push(getInOperator("party_party_role_network", "network_node_id", "IN", null, null, globalConfig.formatters.getInValue(filter.network_node_id)))
          }
          if (filter && filter.network_id != null) {
            operators.push(
              getOperator(
                "party_network_node",
                "network_id",
                "EQUAL",
                globalConfig.formatters.getEqualValue(filter.network_id),
                "network_id"
              )
            );
          }
          
          if (operators.length > 0) {
            qc_header.qc.otherFilters = {
              expressions: [
                {
                  and: {
                    operators,
                  },
                },
              ],
            };
            qc_header.qc.otherOrders = null;
            qc_header.qc.offset = 0;
            qc_header.qc.limit = 50;
          }
      
          const _headers = headers({
            qc: JSON.stringify(qc_header.qc),
          });
      
          axios
            .get(url, {
              headers: _headers,
            })
            .then((res) => {
              const response = res.data.map((resItem: any) => {
              return {
                userName: resItem.id,
                name: resItem.first_name,
                lastName: resItem.family_name,
                objectif:''
              };
            })
              resolve(response);
            })
            .catch((err) => {
                Notify.create({
                  message: `Query returned an error \n ${err}`,
                  color: "negative",
                });
              });
        });
      }

      export function getDocumentsMove(filter: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          const url = settings.api_query_url;
      
          const qc_header: any = {
            qc: {
              queryId: "search-documents-move-new",
            },
          };
          qc_header.qc.parameters = {
            ...filter,
          };
          const operators = [];  
          if (filter && filter.document_type_code != null) {
            operators.push(
              getOperator(
                "supportingdocument_supporting_type",
                "document_type_id",
                "EQUAL",
                globalConfig.formatters.getEqualValue(filter.document_type_code),
                "document_type_id"
              )
            );
          }
          
          
          if (operators.length > 0) {
            qc_header.qc.otherFilters = {
              expressions: [
                {
                  and: {
                    operators,
                  },
                },
              ],
            };
            qc_header.qc.otherOrders = null;
            qc_header.qc.offset = 0;
            qc_header.qc.limit = 200;
          }
      
          const _headers = headers({
            qc: JSON.stringify(qc_header.qc),
          });
      
          axios
            .get(url, {
              headers: _headers,
            })
            .then((res) => {
                 const mappedValues = res?.data.map((item:any) => ({
                  ...item,
                  allReceivedTypesArray: item.all_received_documents_type
                      ? item.all_received_documents_type.split(', ').map((type:any) => type.trim())
                      : []
                 }));
                 const filteredData = mappedValues.filter((item:any) => 
                  !item.allReceivedTypesArray.includes(filter?.document_type_code)
              );
              const response = filteredData
              resolve(response);
            })
            .catch((err) => {
                Notify.create({
                  message: `Query returned an error \n ${err}`,
                  color: "negative",
                });
              });
        });
      }
      export function getPartyDocument(filter: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          const qc_header: any = {
            qc: {
              queryId: "party-party-document",
              offset: 0,
              limit: 50,
            },
          };
          const operators = []
          if (filter && filter.productId != null) {
            operators.push(getOperator("party_party_document", "party_id", "EQUAL", globalConfig.formatters.getEqualValue(filter.partyId), ""))
          }
          if (filter && filter.documentId != null) {
            operators.push(getOperator("party_party_document", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.documentId), ""))
          }
          if (operators.length > 0) {
            qc_header.qc.otherFilters = {
              expressions: [
                {
                  and: {
                    operators
                  }
                }
              ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
          }
      
          const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
          })
          axios
            .get(api_query_url, {
              headers: _headers,
            })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => reject(err));
        });
      }
      export function searchPayplanList(filter: any): Promise<Picklist> {
        return new Promise<Picklist>(async (resolve, reject) => {
          const url = settings.api_query_url;
      
          const qc_header: any = {
            qc: {
              queryId: "search-payplan-list",
            },
          };
          qc_header.qc.parameters = {
            ...filter,
          };
          const operators:any = [];
          if (filter && filter.canal != null &&filter.canal != '') {
            operators.push(
              getOperator("party_network_node","network_id","EQUAL",globalConfig.formatters.getEqualValue(filter.canal),"network_id")
            );
          }
          
          if (filter && filter.objective && filter.objective.value) {
            operators.push(getOperator("order_pay_plan_objective","id","EQUAL",globalConfig.formatters.getEqualValue(filter.objective.value),"id"))
          }
          if (filter && filter.canalNode && filter.canalNode.id) {
            const listCanalNode=[filter.canalNode.id]
            operators.push(getInOperator("party_party_role_network", "network_node_id", "IN", null, null, globalConfig.formatters.getInValue(listCanalNode)))
          }
          if (filter && filter.vendeurList && filter.vendeurList.length>0) {
            let vendeurList=[] 
            for(const vendor of filter.vendeurList){
                vendeurList.push(vendor.id)
            }
            operators.push(getOperator("party_party","id","EQUAL",globalConfig.formatters.getEqualValue(vendeurList),"id"))
          }
          if (filter && filter.startDate ) {

            operators.push(getOperator(
                        "order_pay_plan_objective",
                        "start_period",
                        "GREATER_THAN_EQUAL",
                        //formatDateLocale(filter.startDate, "DD/MM/YYYY", "fr").toString ,
                        globalConfig.formatters.getEqualValue(moment(filter.startDate,  'DD/MM/YYYY').format('YYYY-MM-DD')),
                        "startDate"));
          }
          if (filter && filter.endDate ) {

            operators.push(getOperator(
                        "order_pay_plan_objective",
                        "start_period",
                        "LESS_THAN_EQUAL",
                        //formatDateLocale(filter.endDate, "DD/MM/YYYY", "fr").toString ,
                        globalConfig.formatters.getEqualValue(moment(filter.endDate,  'DD/MM/YYYY').format('YYYY-MM-DD')),
                        "endDate"));
          }

          if (operators.length > 0) {
            qc_header.qc.otherFilters = {
              expressions: [
                {
                  and: {
                    operators,
                  },
                },
              ],
            };
            qc_header.qc.otherOrders = null;
            qc_header.qc.offset = 0;
            qc_header.qc.limit = 50;
          }
      
          const _headers = headers({
            qc: JSON.stringify(qc_header.qc),
          });
      
          axios
            .get(url, {
              headers: _headers,
            })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
                Notify.create({
                  message: `Query returned an error \n ${err}`,
                  color: "negative",
                });
              });
        });
      }

      export async function saveObejectiveSales(obejectiveSales:any){
        for(const Seller of obejectiveSales.Sellers){
            const body:any ={
                beneficiary: {
                  objectType: "odm.party.party.person.frenchperson",
                  systemUid: "odm-party",
                  resourceUid: Seller.id
                },
                fixedValue: obejectiveSales.num ,
                objective: {
                  objectType: "odm.order.payplanobjective",
                  resourceUid: obejectiveSales.objective.value,
                  systemUid: "odm-order"
                },
                objectType: "odm.order.payplan",
                systemUid: "odm-order"
              }

              if(obejectiveSales.resourceUid){
                body.resourceUid=obejectiveSales.resourceUid
              }

              createOrderPayPlan(body)
        }
        const sleepTime =500
        await sleep(sleepTime)
        
      }

      export async function saveOrderPlanning(body:any,orderId?:any){
        const [day, month, year] = body.actionStartDate.split("/");
        const [hour, minute] = body.actionStartHour.split(":");
        const startAssignDate =`${year}-${month}-${day} ${hour}:${minute}:00`;
        const [dayEnd, monthEnd, yearEnd] = body.actionEndDate.split("/");
        const [hourEnd, minuteEnd] = body.actionEndHour.split(":");
        const endAssignDate = `${yearEnd}-${monthEnd}-${dayEnd} ${hourEnd}:${minuteEnd}:00`;
        const assignedUser:any={
          user: {
            objectType: "odm.user.leaseforgeuser",
            systemUid: "odm-user",
            resourceUid: body.assignedUser.value
          },
          startAssignDate: startAssignDate,
          endAssignDate: endAssignDate,
          objectType: "odm.order.assigneduser",
          systemUid: "odm-order"
        }
        if(body.orderAssignedUserId){
          assignedUser.resourceUid=body.orderAssignedUserId
        }
        const assignedUserCreated:any = await saveAssignedUser(assignedUser)
        const bodyOrderPlanning:any={
          comment: body.comment,
          flagConfirmed: body.confirmed,
          planningType: {
            objectType: "odm.order.orderplanningtype",
            resourceUid:body.category
          },
          objectType: "odm.order.orderplanning",
          systemUid: "odm-order",
          assignedUser:{
              objectType: assignedUserCreated.objectType,
              systemUid: assignedUserCreated.systemUid,
              resourceUid: assignedUserCreated.resourceUid
          },
          planningDate: startAssignDate
        }
        if(body.resourceUid){
          bodyOrderPlanning.resourceUid=body.resourceUid
        }
        const orderPlanning = await createOrderPlanning(bodyOrderPlanning,orderId)
        return orderPlanning 
      }

      export function getAssignedUser(filter: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          const qc_header: any = {
            qc: {
              queryId: "assigned-user-search",
              offset: 0,
              limit: 50,
            },
          };
          const operators:any = []
          if (filter && filter.productId != null) {
           // operators.push(getOperator("party_party_document", "party_id", "EQUAL", globalConfig.formatters.getEqualValue(filter.partyId), ""))
          }
          if (filter && filter.documentId != null) {
           // operators.push(getOperator("party_party_document", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.documentId), ""))
          }
          operators.push(getOperator("user_user_group", "group_code", "LIKE", globalConfig.formatters.getEqualValue('Grp_Planification_RDV%'), ""))

          operators.push(getOperator("user_daaq", "read_daaq", "LIKE", globalConfig.formatters.getEqualValue(store.state.authModule?.user?.daaqList[0]?.config?.readDAAQ + '%'), ""))
          if (operators.length > 0) {
            qc_header.qc.otherFilters = {
              expressions: [
                {
                  and: {
                    operators
                  }
                }
              ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
          }
      
          const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
          })
          axios
            .get(api_query_url, {
              headers: _headers,
            })
            .then((res) => {
              const response = res.data.map((resItem: any) => {
                return {
                  value: resItem.id,
                  label: resItem.first_name +' '+resItem.last_name,
                  config:resItem
                };
              })
                resolve(response);
            })
            .catch((err) => reject(err));
        });
      }